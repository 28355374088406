import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { noLogo, baseUrl } from "src/config/host";

const Logo = ({ site, children }) => {
  return (
    <div className="flex items-center h-[56px] flex-shrink-0">
      {children}
      <div className="transition-all duration-75 min-w-[130px]">
        <Link to="/pages"><img className="h-10 sm:h-12 w-auto" src={baseUrl + site?.site_logo} alt="Site logo" onError={(e) => (e.target.src = `${noLogo}`)} /></Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(Logo);
