import { DocumentTextIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { getGroupData, getUserDetails, updateUser } from "src/actions/user";
import { fetchData } from "src/async/fetch";
import AddPermissionForm from "src/components/Permissions/AddPermissionForm";
import Button from "src/components/Shared/Button";
import FormContainer from "src/components/Shared/FormContainer";
import MultiSelect from "src/components/Shared/Forms/MultiSelect";
import PageHeader from "src/components/Shared/PageHeader";
import EditUserForm from "src/components/Users/Edit/EditUserForm";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";

const UserEdit = ({
  groups,
  dashboards,
  operators,
  getDashboardData,
  getOperatorData,
  getUserDetails,
  getGroupData,
  site,
  ...props
}) => {
  const [user, setUser] = useState({});
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const [isAdmin, setIsAdmin] = useState();
  const { id } = useParams();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Assigned Pages");
  const [tabs, setTabs] = useState([
    { name: "Assigned Pages", icon: PresentationChartBarIcon },
    { name: "User Details", icon: DocumentTextIcon },
  ]);

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const tab = useQuery().get("tab");

  useEffect(() => {
    if (user.groups !== undefined) {
      const groupIds = user.groups.map((item, index) => {
        return item._id;
      });
      setSelectedGroups(groupIds);
    }
  }, [user.groups]);

  const loadUsersAndDeps = async (ac = {}) => {
    try {
      await getDashboardData({}, ac.signal);
      const op = await getOperatorData({}, ac.signal);
      await getGroupData({}, ac.signal);
      let res = await fetchData("POST", `${apiUrl}/user/details`, { id }, ac.signal);
      res = await res.json();

      setUser({ ...res.data });
      const finalPermissions = {};
      const updatedStateCount = [];
      res.data.permissions.forEach((permission, i) => {
        const operatorIndex = op.findIndex((item) => item._id === permission?.operator_id);

        if (finalPermissions[permission?.dashboard_id]) {
          finalPermissions[permission?.dashboard_id].filters.push({
            operator: {
              ...op[operatorIndex],
              name: operatorNameConverter(op[operatorIndex]?.name),
            },
            operator_id: permission?.operator_id,
            column_name: permission?.column_name,
            column_value: permission?.column_value,
          });
        } else {
          let permisn = {
            dashboard_id: permission?.dashboard_id,
            filters: [
              {
                operator: {
                  ...op[operatorIndex],
                  name: operatorNameConverter(op[operatorIndex]?.name),
                },
                operator_id: permission?.operator_id,
                column_name: permission?.column_name,
                column_value: permission?.column_value,
              },
            ],
          };
          finalPermissions[permission?.dashboard_id] = permisn;
        }
      });

      let permission = {};
      Object.values(finalPermissions).forEach((permiss, i) => {
        permission[i] = permiss;
        updatedStateCount.push(i);
        return true;
      });
      setSelectedGroups(res.data.groups);
      setPermissionState(permission);
      setPermissionFormCount(updatedStateCount);
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    loadUsersAndDeps(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.type !== undefined) {
      if (user.type === "admin") {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        if (localStorage.getItem("previewUserId")) {
          setTabs([{ name: "User Details", icon: DocumentTextIcon }]);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (isAdmin !== undefined) {
      if (isAdmin === true) {
        setUser({ ...user, type: "admin" });
      } else {
        setUser({ ...user, type: "user" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  useEffect(() => {
    if (tab === "dashboards") {
      setSelectedTab("Assigned Pages");
    } else {
      setSelectedTab("User Details");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    let permission = [];
    let orderValue = 1;
    Object.values(permissionState).map((pe) => {
      pe.filters.map((flt) => {
        permission.push({
          dashboard_id: pe.dashboard_id,
          operator_id: flt.operator_id,
          column_name: flt.column_name,
          column_value: flt.column_value,
          ordering: orderValue,
        });
        orderValue++;
        return true;
      });
      return true;
    });

    const updateData = {
      ...user,
      groups: selectedGroups,
      permissions: permission,
    };

    try {
      const message = await props.updateUser(updateData);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const handleSetUser = (value, key) => {
    setUser({ ...user, [key]: value });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Object.values(permissionState),
      result.source.index,
      result.destination.index
    );
    setPermissionState({ ...items });
  };

  return (
    <>
      <PageHeader>Edit {user.type === "admin" ? "Admin" : "User"}</PageHeader>
      <div className="overflow-auto border-b border-gray-200 sm:overflow-hidden">
        <nav
          className="-mb-px flex space-x-8"
          aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => {
                setSelectedTab(tab.name);
                loadUsersAndDeps();
              }}
              className={classNames(
                tab.name === selectedTab
                  ? "border-highlightColor text-highlightColor"
                  : "border-transparent text-gray-400 hover:border-gray-400 hover:text-gray-500",
                "group inline-flex cursor-pointer items-center border-b-2 px-1 pb-3 text-sm font-medium"
              )}
              aria-current={tab.name === selectedTab ? "page" : undefined}>
              <tab.icon
                className={classNames(
                  tab.name === selectedTab
                    ? "text-highlightColor"
                    : "text-gray-400 group-hover:text-gray-400",
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
              <span className="block whitespace-nowrap">{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="mt-3">
        {tabs.find((tab) => tab.name === selectedTab).name === "User Details" && (
          <EditUserForm
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            name={user.name}
            password={user.password}
            confirm_password={user.confirm_password}
            email={user.email}
            username={user.username}
            image={user.image}
            notifications={user.notifications}
            user={user}
            setUser={handleSetUser}
          />
        )}
        {tabs.find((tab) => tab.name === selectedTab).name === "Assigned Pages" && (
          <div className="grid gap-y-4">
            <div className="flex flex-col gap-y-2">
              <div className="mt-3 flex w-full items-center justify-between">
                <h3 className="pl-2 pb-1 text-xl font-semibold text-gray-500">Assign Groups</h3>
              </div>
              <FormContainer className="pl-4">
                <div className="w-full px-2 sm:w-1/2">
                  <MultiSelect
                    options={groups}
                    selectedOptions={selectedGroups}
                    setSelectedOptions={setSelectedGroups}></MultiSelect>
                </div>
              </FormContainer>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="mt-3 flex w-full items-center justify-between">
                <h3 className="pl-2 pb-1 text-xl font-semibold text-gray-500">
                  Assign permissions
                </h3>
                <div className="flex justify-end gap-x-3">
                  <Button
                    styleType="gray"
                    onClick={addNewPermission}>
                    <PlusIcon className="h-4 w-4" />
                    Add permission
                  </Button>
                </div>
              </div>
              <FormContainer className="px-3">
                <div className="w-full grid lg:mt-1">
                  {permissionFormCount.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable-user">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {permissionFormCount.map((count, i) => {
                              return (
                                <Draggable
                                  key={i}
                                  draggableId={`${i}`}
                                  index={i}>
                                  {(provided, snapshot) => (
                                    <AddPermissionForm
                                      innerRef={provided.innerRef}
                                      draggableProps={provided.draggableProps}
                                      dragHandleProps={provided.dragHandleProps}
                                      key={i}
                                      index={count}
                                      dashboards={dashboards}
                                      operators={operators?.map((operator) => {
                                        return {
                                          ...operator,
                                          name: operatorNameConverter(operator.name),
                                          id: operator._id,
                                        };
                                      })}
                                      formCount={permissionFormCount}
                                      setFormCount={setPermissionFormCount}
                                      state={permissionState}
                                      setState={setPermissionState}
                                    />
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <p className="m-0 inline text-md font-medium text-gray-400 ml-3">
                      <span className="px-3.5 py-3 rounded-md bg-gray-50">
                        No permissions assigned
                      </span>
                    </p>
                  )}
                </div>
              </FormContainer>
            </div>
          </div>
        )}
      </div>
      {/* <GroupModal setRefresh={setRefresh} /> */}
      <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
        <Button
          styleType="gray"
          type="button"
          onClick={loadUsersAndDeps}>
          Clear all
        </Button>
        <Button
          type="button"
          onClick={onSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    user: state.userDetails,
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, {
  getUserDetails,
  updateUser,
  getGroupData,
  getDashboardData,
  getOperatorData,
})(UserEdit);
