import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { verifyToken } from "src/actions/auth";

const VerifyToken = ({ verifyToken }) => {
  const [status, setStatus] = useState("pending");
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const ac = new AbortController();

    const loadToken = async () => {
      try {
        const { message, data } = await verifyToken({ token: params.token || null }, ac.signal);
        setStatus("success");

        toast.success(message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return navigate(`/reset-password/${data}`);
      } catch (error) {
        setStatus("failed");
        toast.error(error.message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    loadToken();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-lg-12">
      <h5 className="text-left" style={{ fontSize: "20px", fontWeight: 700 }}>
        {status === "pending" ? "Verifying token..." : status === "failed" ? "Invalid token" : status === "success" ? "Token verified." : ""}
      </h5>
    </div>
  );
};

export default connect(null, { verifyToken })(VerifyToken);
