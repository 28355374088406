import React from "react";
import { connect } from "react-redux";

const AuthHeader = ({ title, switchTo }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      {/* <a href="#!">{site?.site_logo && <img className="mx-auto h-20 w-auto rounded-md" src={site.site_logo || noProfile} alt="Logo" onError={(e) => (e.target.src = `${noProfile}`)} />}</a> */}
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{title}</h2>
      <p className="mt-2 text-center text-regular font-medium text-gray-600">
        {switchTo.startText}
        <a href={switchTo.location} target="_blank" rel="noreferrer" className="hover:underline pl-1 text-highlightColor">
          {switchTo.text}
        </a>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(AuthHeader);
