import { Fragment } from "react";
import SelectMenu from "src/components/Shared/SelectMenu";

const options = [
  { key: "10", value: 10 },
  { key: "20", value: 25 },
  { key: "50", value: 50 },
  { key: "75", value: 75 },
  { key: "100", value: 100 },
  { key: "All", value: 1000000000 },
];

const NavigationCrumbs = ({ offset, setPerPage = () => {}, perPage = 10, pages, setOffset }) => {
  return (
    <div className="flex flex-wrap items-center py-3">
      <div className="mx-auto my-2 flex items-center gap-x-2 text-sm text-gray-400 sm:ml-0">
        Showing
        <SelectMenu
          options={options}
          startIndex={options.findIndex((option) => option.value === perPage)}
          setOption={(option) => {
            setPerPage(option.value);
            setOffset(0);
          }}
        />{" "}
        per page.
      </div>
      <div className="flex w-full flex-wrap items-center justify-center space-x-1 sm:w-auto sm:justify-end">
        {Array(pages)
          .fill()
          .map((_, i, arr) => {
            return (
              <Fragment key={i}>
                {offset > 0 && i === 0 && (
                  <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50" onClick={() => setOffset(offset - 1)}>
                    <i className="far fa-fw fa-chevron-double-left"></i>
                  </div>
                )}
                {[3].includes(i) && [0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(offset) && (
                  <div className="flex h-8 w-auto items-end gap-x-1 px-2">
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                  </div>
                )}
                {arr.length > 9 && ([0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(i) || ([2, arr.length - 3].includes(offset) && offset === i)) ? (
                  <>
                    <div className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50 ${offset === i ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""}`} onClick={() => setOffset(i)}>
                      {i + 1}
                    </div>
                  </>
                ) : offset === i && offset > 2 && offset <= arr.length - 3 && ![0, 1, 2, arr.length - 3, arr.length - 2, arr.length - 1].includes(i) ? (
                  <>
                    <div className="flex h-8 w-auto items-end gap-x-1 px-2">
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    </div>
                    <div className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50 ${offset === i ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""}`} onClick={() => setOffset(i)}>
                      {i + 1}
                    </div>
                    <div className="flex h-8 w-auto items-end gap-x-1 px-2">
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    </div>
                  </>
                ) : null}
                {offset + 1 !== pages && i + 1 === pages && (
                  <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50" onClick={() => setOffset(offset + 1)}>
                    <i className="far fa-fw fa-chevron-double-right"></i>
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default NavigationCrumbs;
