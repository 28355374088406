import { Navigate, Route, Routes } from "react-router-dom";
import Activity from "src/pages/activitySection";
import LoginContent from "src/components/Auth/LoginContent";
import { FeatureFlagRoute } from "src/components/FeatureFlags/FeatureFlagRoute";
import GroupList from "src/pages/groupSection";
import ListSSO from "src/pages/listSSO";
import AdminUsers from "src/pages/adminUsers";
import BulkUserAdd from "src/pages/bulkUserAdd";
import ChangePassword from "src/pages/changePassword";
import Pages from "src/pages/pages";
// import DatasetUsers from "src/pages/datasetUsers";
import Analytics from "src/pages/analytics";
import ForgotPassword from "src/pages/forgotPassword";
import ManageUser from "src/pages/manageUser";
import ManageUserEdit from "src/pages/manageUserEdit";
import ResetPassword from "src/pages/resetPassword";
import RouteNotFound404 from "src/pages/routeNotFound404";
import SiteSettings from "src/pages/siteSettings";
import SSOServiceProvider from "src/pages/ssoDetails";
import Users from "src/pages/users";
import VerifyToken from "src/pages/verifyToken";
import FreeAuthRoute from "src/routes/FreeAuthRoute";
import ProtectedRoute from "src/routes/ProtectedRoute";
// import Emailer from "src/pages/emailer";
import QlikAnalytics from "src/pages/qlikAnalytics";

//Router
export default (
  <Routes>
    <Route
      path="/"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/login"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/sso-logout"
      element={
        <FreeAuthRoute>
          <LoginContent />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <FreeAuthRoute>
          <ForgotPassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/verify-token/:token"
      element={
        <FreeAuthRoute>
          <VerifyToken />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/reset-password/:token"
      element={
        <FreeAuthRoute>
          <ResetPassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/activity"
      element={
        <ProtectedRoute roles={["admin"]}>
          <FeatureFlagRoute feature="Activity">
            <Activity />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/groups"
      element={
        <ProtectedRoute roles={["admin"]}>
          <GroupList />
        </ProtectedRoute>
      }
    />

    <Route
      path="/users"
      element={
        <ProtectedRoute roles={["admin"]}>
          <Users />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admins"
      element={
        <ProtectedRoute roles={["admin"]}>
          <AdminUsers />
        </ProtectedRoute>
      }
    />
    {/* <Route
      path="/dataset-users"
      element={
        <ProtectedRoute roles={["admin"]}>
          <DatasetUsers portal={true} />
        </ProtectedRoute>
      }
    /> */}
    <Route
      path="/pages"
      element={
        <ProtectedRoute roles={["admin"]}>
          <Pages />
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso"
      element={
        <ProtectedRoute roles={["admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <ListSSO />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/add"
      element={
        <ProtectedRoute roles={["admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOServiceProvider />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/edit/:id"
      element={
        <ProtectedRoute roles={["admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOServiceProvider />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/site-settings"
      element={
        <ProtectedRoute roles={["admin"]}>
          <SiteSettings />
        </ProtectedRoute>
      }
    />
    <Route
      path="/manage-user"
      element={
        <ProtectedRoute roles={["admin"]}>
          <ManageUser />
        </ProtectedRoute>
      }
    />
    <Route
      path="/manage-admin"
      element={
        <ProtectedRoute roles={["admin"]}>
          <ManageUser />
        </ProtectedRoute>
      }
    />
    <Route
      path="/update-user/:id"
      element={
        <ProtectedRoute>
          <ManageUserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/update-admin/:id"
      element={
        <ProtectedRoute roles={["admin"]}>
          <ManageUserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/change-password"
      element={
        <ProtectedRoute>
          <ChangePassword />
        </ProtectedRoute>
      }
    />

    <Route
      path="/bulk-user-upload"
      element={
        <ProtectedRoute roles={["admin"]}>
          <BulkUserAdd />
        </ProtectedRoute>
      }
    />

    {/* <Route
      path="/emailer"
      element={
        <ProtectedRoute roles={["admin"]}>
          <Emailer />
        </ProtectedRoute>
      }
    /> */}

    <Route
      path="/analytics/:id"
      element={
        <ProtectedRoute roles={["user"]}>
          <Analytics />
        </ProtectedRoute>
      }
    />

    <Route
      path="/qlik-analytics"
      element={
        <ProtectedRoute>
          <QlikAnalytics />
        </ProtectedRoute>
      }
    />
    <Route path="/manage-user" roles={["admin"]} element={<ManageUser />} />
    <Route path="/404" element={<RouteNotFound404 />} />
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);
