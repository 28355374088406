import { ArrowPathIcon, ChevronDownIcon, EllipsisVerticalIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Button";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import { classNames } from "src/helpers/classNames";
import FilterRow from "./FilterRow";

const AddPermissionForm = ({ isGroup, dashboards, isDefault, updateDefaultKey, operators, state, setState, index, usesTrustedValue = false, draggableProps, dragHandleProps, innerRef, ...props }) => {
  const [dashboardId, setDashboardId] = useState({ _id: -1, name: "None selected" });
  const [dashboardType, setDashboardType] = useState("");
  const [isHideFilter, setIsHideFilter] = useState(true);
  const trustedAttributeArr = [
    {
      _id: "user.firstName",
      value: "user.firstName",
      name: "user.firstName",
    },
    {
      _id: "user.lastName",
      value: "user.lastName",
      name: "user.lastName",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "user.email",
    },
    {
      _id: "user.username",
      value: "user.username",
      name: "user.username",
    },
    {
      _id: "user.company",
      value: "user.company",
      name: "user.company",
    },
    {
      _id: "user.department",
      value: "user.department",
      name: "user.department",
    },
  ];

  const embedTypes = {
    embed: "DOMO Page",
    card: "DOMO Card",
    custom: "Custom HTML",
  };

  useEffect(() => {
    let selectedDashboard = dashboards.filter((dashboard) => dashboard._id === state[index]?.dashboard_id)[0];
    setDashboardId(selectedDashboard);
    if (selectedDashboard) {
      setDashboardType(selectedDashboard.dashboard_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboards]);

  // const [trustedAttribute, setTrustedAttribute] = useState({
  //   _id: -1,
  //   name: "None selected",
  // });

  const removeForm = (e) => {
    e.preventDefault();
    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const clearForm = (e) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        filters: state[index].filters.map((filter) => {
          return {
            ...filter,
            operator: {
              _id: -1,
              name: "None selected",
            },
            operator_id: null,
            column_name: "",
            column_value: "",
          };
        }),
      },
    });
  };

  const updateForm = (e, name, next) => {
    if (name === "dashboard_id") {
      setDashboardType(e.dashboard_type);
    }
    // eslint-disable-next-line eqeqeq
    next({ _id: e._id, name: e.name });
    if (e._id !== -1) {
      handleState(name, e._id || e._id);
    } else {
      handleState(name, "");
    }
  };

  const handleState = (name, value) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [name]: value,
      },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        dashboard_id: state[index]?.dashboard_id,
        filters: state[index]?.filters
          ? state[index].filters
          : [
              {
                operator: {
                  _id: -1,
                  name: "None selected",
                },
                operator_id: null,
                column_name: "",
                column_value: "",
              },
            ],
      },
    });
    setDashboardId({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state[index] && state[index].dashboard_id) {
      const dashboardIndex = dashboards.findIndex((item) => item._id === state[index].dashboard_id);
      if (dashboardIndex !== -1) {
        setDashboardId(dashboards[dashboardIndex]);
        updateForm(dashboards[dashboardIndex], "dashboard_id", setDashboardId);
      }
    } else {
      setDashboardId({});
      setDashboardType("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.dashboard_id]);

  // useEffect(() => {
  //   if (state[index] && state[index].trusted_attribute?.includes("user.")) {
  //     setTrustedAttribute({
  //       _id: state[index].trusted_attribute,
  //       value: state[index].trusted_attribute,
  //       name: state[index].trusted_attribute,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state[index]?.trusted_attribute]);

  const addNewFilter = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [index]: {
        dashboard_id: state[index]?.dashboard_id,
        filters: [
          ...state[index].filters,
          {
            operator: {
              _id: -1,
              name: "None selected",
            },
            operator_id: null,
            column_name: "",
            column_value: "",
          },
        ],
      },
    });
  };

  const removeFilter = (e) => {
    delete state[index].filters[e];
    const filtered = state[index].filters.filter(function (el) {
      return el != null;
    });
    setState({
      ...state,
      [index]: {
        ...state[index],
        filters: filtered,
      },
    });
  };

  return (
    <div ref={innerRef} {...draggableProps} className={"relative px-4 rounded-md border-invisible transition-colors duration-300 group bg-white/90" + (isGroup?' !top-auto !left-auto':'')}>
      <p onMouseDownCapture={() => setIsHideFilter(true)} {...dragHandleProps} className="h-[35px] w-[12px] mt-1 flex items-center justify-center sm:group-hover:flex hover:bg-gray-100 transition-colors duration-300 hover:text-gray-900 absolute z-30 left-0 top-5 rounded-sm text-gray-700">
        <EllipsisVerticalIcon className="absolute text-gray-400 h-5 w-5" />
      </p>
      <div className="flex w-full items-end relative gap-y-4 rounded-md px-2 py-0">
        <div className="w-full flex gap-y-4 lg:mr-[8px] gap-x-4 items-end justify-between">
          <div className="flex w-2/3 items-end gap-x-3">
            <div className="flex w-full md:w-2/3">
              {/* Dashboard */}
              <div className="w-full">
                <SimpleDropdown label={"Dashboard"} secondaryLabel={embedTypes[dashboardType]} options={dashboards} selected={dashboardId} setSelected={(e) => updateForm(e, "dashboard_id", setDashboardId)} />
              </div>
            </div>
            <div className="h-full items-end gap-x-3 hidden sm:flex">
              <button type="button" className="h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={clearForm}>
                <span className="sr-only">Clear</span>
                <ArrowPathIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <button type="button" className="h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={removeForm}>
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          {dashboardType !== "custom" &&
            (state[index]?.filters && state[index]?.filters.length > 0 ? (
              <div className="h-[34px]">
                <button onClick={() => setIsHideFilter(!isHideFilter)} type="button" className="h-[34px] inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium focus:ring-0 text-gray-500 border-gray-300 hover:text-highlightColor transition-all duration-75 min-w-[140px] gap-x-2">
                  <div className="flex items-center justify-center">
                    {isHideFilter ? "Show" : "Hide"} Filters ({state[index]?.filters.length})
                  </div>
                  <ChevronDownIcon className={classNames("h-4 w-4 transition-all duration-150", isHideFilter ? "-rotate-90" : "")} aria-hidden="true" />
                </button>
              </div>
            ) : (
              <Button styleType="gray" onClick={addNewFilter}>
                <PlusIcon className="h-4 w-4" />
                Add filter
              </Button>
            ))}
        </div>
      </div>
      <div>
        <div className={classNames("px-2 lg:max-w-[1200px] duration-300", !isHideFilter ? "max-h-[2000px]" : "max-h-[0px]")}>
          <div className="mt-4 flex flex-col gap-y-2 relative">
            <AnimatePresence>
              {!isHideFilter && dashboardType !== "custom" && state[index]?.filters && state[index]?.filters.length > 0 ? (
                <>
                  {state[index]?.filters.map((count, i) => (
                    <FilterRow key={"filter_" + i + "_" + index} dashboardType={dashboardType} trustedAttributeArr={trustedAttributeArr} usesTrustedValue={usesTrustedValue} operators={operators} count={count} i={i} state={state} index={index} removeFilter={removeFilter} setState={setState} />
                  ))}
                  <div className="mt-4 mb-5 flex items-center justify-end pr-6">
                    <Button styleType="gray" onClick={addNewFilter}>
                      <PlusIcon className="h-4 w-4" />
                      Add filter
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPermissionForm;
