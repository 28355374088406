import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeDefault } from "src/actions/sso";
import QuickMenu from "src/components/Shared/QuickMenu";
import { classNames } from "src/helpers/classNames";
import { motion, useIsPresent } from "framer-motion";

const SSOEntry = ({ sso, makeDefault, setDeleteId, site, ...props }) => {
  const navigate = useNavigate();

  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.tr
      key={sso?._id}
      className="flex flex-wrap sm:table-row relative"
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
    >
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-0 pt-3 px-3 sm:py-3.5 text-sm text-gray-500">
        <div className="text-gray-900 font-medium">{sso?.provider_name}</div>
      </td>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-1 px-3 sm:py-3.5 text-xs text-gray-500">
        <div className="text-gray-700 max-w-xs">{sso?.identity_url}</div>
      </td>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-1 px-3 sm:py-3.5 text-xs text-gray-500">
        <div className="text-gray-700 max-w-xs">{sso?.login_url}</div>
      </td>
      <td className="flex items-center justify-end w-full pt-0 pb-3 sm:w-20 sm:table-cell whitespace-nowrap px-3 sm:py-3.5 text-sm text-gray-500 ">
        {sso.default && (
          <button
            onClick={() => makeDefault({ id: sso._id, default: false })}
            className={classNames(
              "flex justify-center w-[60px] rounded-full px-2 text-xs font-semibold leading-5",
              sso.default ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-100 text-gray-500"
            )}
          >
            {sso.default ? "Active" : "Inactive"}
          </button>
        )}
        {!sso.default && (
          <button
            onClick={() => makeDefault({ id: sso._id, default: true })}
            className={classNames(
              "flex justify-center w-[60px] rounded-full px-2 text-xs font-semibold leading-5",
              sso.default ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-100 text-gray-500"
            )}
          >
            {sso.default ? "Active" : "Inactive"}
          </button>
        )}
      </td>
      <td className="absolute top-0 right-0 block sm:table-cell sm:relative whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-6">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/sso/edit/${sso._id}`);
                },
              },
              {
                name: "Delete",
                showWhen: !sso.default,
                onClick: () => {
                  setDeleteId(sso._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { makeDefault })(SSOEntry);
