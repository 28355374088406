import React, { useEffect, useState } from "react";
import ToggleFlags from "src/components/FeatureFlags/ToggleFlags";
import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";

export const FeatureFlags = React.createContext({
  flags: [],
  setFlags: () => {},
});

export const FeatureFlagsProvider = ({ children, value }) => {
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        const res = await fetchData("POST", `${apiUrl}/menu/list`, {}, ac.signal);
        const resData = await res.json();
        setFlags(resData.data);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  const editFlags = async (active, name) => {
    let foundFlag = flags.find((flag) => flag.name === name);
    foundFlag.active = active;
    try {
      await fetchData("POST", `${apiUrl}/menu/edit`, foundFlag);

      let res = await fetchData("POST", `${apiUrl}/menu/list`, {});
      res = await res.json();

      if (res.status === 200) {
        setFlags(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <FeatureFlags.Provider value={flags}>
      {children}
      <ToggleFlags editFlags={editFlags} />
    </FeatureFlags.Provider>
  );
};
