import React, { useEffect } from "react";
import engine from "src/helpers/engine";

export default function QlikAnalytics(props) {
  useEffect(() => {
    if (engine) {
      //Get List of apps
      const getApps = (engine) => {
        engine.then((engine) => {
          engine.getDocList().then((apps) => {
            //update state with list of apps from object
          });
        });
      };

      getApps(engine);
    }
  }, [props.engine]);

  return <div>QlikAnalytics</div>;
}
