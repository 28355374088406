import { motion, useIsPresent } from "framer-motion";
import { connect } from "react-redux";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "../Shared/Table/TRBody";

const GroupEntry = ({ group, setDeleteId, site, ...props }) => {
  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
      key={group?._id}
      className="relative"
    >
      <TRBody className="whitespace-pre-wrap">
        <div className="text-gray-900 font-medium">{group?.name}</div>
      </TRBody>

      <TRBody className="whitespace-pre-wrap">
        <div className="text-gray-900 font-medium">
          {
            group?.permissions.reduce((dashboards, permission) => {
              if (dashboards.includes(permission.dashboard_id)) {
                return dashboards;
              } else {
                return [...dashboards, permission.dashboard_id];
              }
            }, []).length
          }
        </div>
      </TRBody>
      <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  // navigate(`/group/edit/${group._id}`);
                  props.setEditId(group._id);
                  props.setGroupModalOpen(true);
                  props.setEditGroupStatus(true);
                  props.setAddGroupStatus(false);
                },
              },
              {
                name: "Delete",
                onClick: () => {
                  setDeleteId(group._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(GroupEntry);
