import React, { useEffect, useState } from "react";
import { formatName } from "src/helpers/formatName";
import { baseUrl } from "src/config/host";

export default function UserProfile({ user }) {
  const [isImgAvail, setIsImgAvail] = useState(true);

  const onErrorImg = () => {
    setIsImgAvail(false);
  };

  useEffect(() => {
    setIsImgAvail(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.image]);
  return isImgAvail ? <img className="h-full w-full object-cover" src={baseUrl + user?.image} alt="profile" onError={onErrorImg} /> : <div className="pb-[1px] w-full h-full rounded-full flex justify-center items-center text-gray-900 text-sm font-medium uppercase bg-gray-200">{formatName(user?.name, user?.email)}</div>;
}
