import { AnimatePresence } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteSso, getSSODataCount } from "src/actions/sso";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import NavigationCrumbs from "src/components/Navigation/NavigationCrumbs";
import PageHeader from "src/components/Shared/PageHeader";
import CardSSOForm from "../components/SSO/CardSSOForm";
import SSOEntry from "../components/SSO/SSOEntry";
import SSOList from "../components/SSO/SSOList";

const ListSSO = ({ sso, getSSODataCount, deleteSso }) => {
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadSSO = async () => {
      setLoaded(false);
      const params = { limit: perPage, offset: offset * perPage };

      try {
        const ssoCount = await getSSODataCount(params, ac.signal);
        setCount(ssoCount);

        const availablePages = Math.ceil(ssoCount / perPage);
        setPages(availablePages);
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
        console.dir(error.message);
      }
    };

    loadSSO();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * perPage + 1;
  };

  const removeSSO = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteSso(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <PageHeader>SSO</PageHeader>
      <CardSSOForm sso={sso} setLoaded={setLoaded} perPage={perPage} offset={offset} />
      <ModalDefault isOpen={!!deleteId} closeModal={() => setDeleteId(null)} onSubmit={removeSSO} submitText="Delete" cancelText="Cancel" title="Delete sso">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {sso.find((item) => item._id === deleteId)?.provider_name}?</div>
      </ModalDefault>
      <AnimatePresence>
        <SSOList setDeleteId={setDeleteId} deleteId={deleteId} loaded={loaded} dataExists={sso.length}>
          {sso.length > 0 &&
            sso.map((item) => (
              <Fragment key={item._id}>
                <SSOEntry key={item._id} sso={item} deleteId={deleteId} setDeleteId={setDeleteId} />
              </Fragment>
            ))}
        </SSOList>
      </AnimatePresence>
      {/* {count > perPage}/{sso.length > 0 ? "true" : "false"}/{loaded ? "true" : "false"} */}
      {(count >= perPage || count === sso.length) && sso.length > 0 && loaded && <NavigationCrumbs count={count} pages={pages} getOffsetPageCount={getOffsetPageCount} setCount={setCount} perPage={perPage} setPerPage={setPerPage} offset={offset} setOffset={setOffset} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sso: Object.values(state.sso),
  };
};

export default connect(mapStateToProps, { getSSODataCount, deleteSso })(ListSSO);
