import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addGroup, editGroupData } from "src/actions/user";
import AddPermissionForm from "src/components/Permissions/AddPermissionForm";
import Input from "src/components/Shared/Forms/Input";

import { PlusIcon } from "@heroicons/react/24/solid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "src/components/Shared/Button";
import { noBusiness } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import FormContainer from "../Shared/FormContainer";
import ModalDefault from "../Shared/Modals/ModalDefault";
import ProfileImage from "./Edit/ProfileImage";

const GroupModal = ({ isOpen = false, closeModal = () => {}, submitText, cancelText, title, addGroup, children, onSubmit = () => {}, removeSubmit = false, removeClose = false, editGroupData, ...props }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [permissionFormCount, setPermissionFormCount] = useState([]);
  const [permissionState, setPermissionState] = useState({});
  const [group, setGroup] = useState({});
  const [advancedFeatures, setAdvancedFeatures] = useState(false);

  const createGroup = async () => {
    try {
      let permission = [];
      let orderValue = 1;
      Object.values(permissionState).map((pe) => {
        pe.filters.map((flt) => {
          permission.push({
            dashboard_id: pe.dashboard_id,
            operator_id: flt.operator_id,
            column_name: flt.column_name,
            column_value: flt.column_value,
            ordering: orderValue,
          });
          orderValue++;
          return true;
        });
        return true;
      });

      const data = await addGroup({
        group_name: name,
        group_image: image,
        permissions: permission,
      });
      setName("");
      setImage("");
      setPermissionState({});
      props.setIsOpen(false);
      props.setAddGroupStatus(false);
      props.setEditGroupStatus(false);
      props.setRefresh(data.data._id);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editGroup = async () => {
    try {
      let permission = [];
      let orderValue = 1;
      Object.values(permissionState).map((pe) => {
        pe.filters.map((flt) => {
          permission.push({
            dashboard_id: pe.dashboard_id,
            operator_id: flt.operator_id,
            column_name: flt.column_name,
            column_value: flt.column_value,
            ordering: orderValue,
          });
          orderValue++;
          return true;
        });
        return true;
      });

      const data = await editGroupData({
        id: group?._id,
        group_name: name,
        group_image: image,
        name,
        permissions: permission,
      });
      setName("");
      setImage("");
      setPermissionState({});
      props.setIsOpen(false);
      props.setAddGroupStatus(false);
      props.setEditGroupStatus(false);
      props.setRefresh(data.data._id);
      toast.success(data.message);
      props.setEditId(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const groupModalSubmit = async () => {
    if (props.addGroupStatus) {
      await createGroup();
    } else if (props.editGroupStatus) {
      await editGroup();
    }

    return;
  };

  useEffect(() => {
    if (props?.editId && props.groups.length) {
      const findGroup = props.groups.find((group) => group._id === props?.editId);
      setGroup(findGroup);
      if (findGroup && findGroup.permissions && findGroup.permissions.length) {
        const updatedStateCount = [];
        const finalPermissions = {};

        findGroup.permissions.forEach((permission, i) => {
          const operatorIndex = props.operators.findIndex((item) => item._id === permission?.operator_id);

          if (finalPermissions[permission?.dashboard_id]) {
            finalPermissions[permission?.dashboard_id].filters.push({
              operator: { ...props.operators[operatorIndex], name: operatorNameConverter(props.operators[operatorIndex]?.name) },
              operator_id: permission?.operator_id,
              column_name: permission?.column_name,
              column_value: permission?.column_value,
            });
          } else {
            let permisn = {
              dashboard_id: permission?.dashboard_id,
              filters: [
                {
                  operator: { ...props.operators[operatorIndex], name: operatorNameConverter(props.operators[operatorIndex]?.name) },
                  operator_id: permission?.operator_id,
                  column_name: permission?.column_name,
                  column_value: permission?.column_value,
                },
              ],
            };
            finalPermissions[permission?.dashboard_id] = permisn;
          }
        });

        let permission = {};
        Object.values(finalPermissions).forEach((permiss, i) => {
          permission[i] = permiss;
          updatedStateCount.push(i);
          return true;
        });

        setPermissionState(permission);
        setPermissionFormCount(updatedStateCount);
      }

      setName(findGroup?.name);
      setImage(findGroup?.image);
    } else {
      setGroup({});
      setPermissionState({});
      setPermissionFormCount([]);
      setName("");
      setImage("");
      // const uid = v4();
      // setPermissionState({
      //   [uid]: {
      //     _id: uid,
      //     dashboard_id: null,
      //     operator_id: null,
      //     column_name: '',
      //     column_value: '',
      //   }
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editId]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(Object.values(permissionState), result.source.index, result.destination.index);
    setPermissionState({ ...items });
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      closeModal={() => {
        props.setIsOpen(!isOpen);
        props.setEditId(null);
      }}
      onSubmit={() => {
        onSubmit();
        groupModalSubmit();
      }}
      submitText="Save"
      cancelText="Cancel"
      title="Add a Group"
    >
      {/* Group name */}
      <div className="flex flex-wrap -mx-2">
        <div className="w-full px-2 mb-4">
          <Input name="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
      </div>
      <div className="flex justify-end">
        <div onClick={() => setAdvancedFeatures(!advancedFeatures)} className="-mt-[10px] py-1 px-2 rounded-md hover:bg-gray-50 cursor-pointer inline-flex items-center gap-x-1 text-gray-300 transition-all duration-200 hover:text-gray-400">
          <p>Advanced features</p>
          <ChevronDownIcon className={classNames("h-5", advancedFeatures ? "" : "-rotate-90")} />
        </div>
      </div>
      {/* Group image */}
      <div className={classNames("ml-4 mb-4 transition-all duration-150 overflow-hidden", advancedFeatures ? "max-h-[120px] opacity-100" : "max-h-[0px] opacity-20")}>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full px-2 mb-4">
            <p className="text-gray-300 text-sm">*Individuals assigned to this group will show this logo instead of company logo</p>
            <ProfileImage
              baseImage={noBusiness}
              image={image}
              user={group}
              setImage={(value) => {
                setImage(value);
                setGroup({ ...group, image: value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="mt-3 flex w-full items-center justify-between">
          <h3 className="pl-2 pb-1 text-xl font-semibold text-gray-500">Assign permissions</h3>
          <div className="flex justify-end gap-x-3">
            <Button styleType="gray" onClick={addNewPermission}>
              <PlusIcon className="h-4 w-4" />
              Add permission
            </Button>
          </div>
        </div>
        <FormContainer className="-px-4">
          <div className="relative w-full grid lg:mt-1 sm:h-[440px] overflow-y-scroll">
            {permissionFormCount.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {permissionFormCount.map((count, i) => {
                        return (
                          <Draggable key={i} draggableId={`${i}`} index={i}>
                            {(provided, snapshot) => (
                              <AddPermissionForm
                                isGroup={true}
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                key={i}
                                index={count}
                                dashboards={props.dashboards}
                                permissions={group?.permissions}
                                operators={props.operators?.map((operator) => {
                                  return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                                })}
                                formCount={permissionFormCount}
                                setFormCount={setPermissionFormCount}
                                state={permissionState}
                                setState={setPermissionState}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <p className="m-0 mt-1 inline text-md font-medium text-gray-400 ml-3">
                <span className="px-3.5 py-3 rounded-md bg-gray-50">No permissions assigned</span>
              </p>
            )}
          </div>
        </FormContainer>
      </div>
    </ModalDefault>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { addGroup, editGroupData })(GroupModal);
