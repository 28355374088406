import React from "react";
import HelmetHeader from "src/components/Shared/HelmetHeader";

const AuthLayout = ({ children, ...props }) => {
  return (
    <>
      <HelmetHeader title={props?.site.name} />
      <div className="min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">{children}</div>
    </>
  );
};

export default AuthLayout;
