import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import {
  GET_EMAILER_DATA,
  REMOVE_EMAILER,
  LOG_OUT
} from "src/actions/type";

const emailerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EMAILER_DATA:
      return { ...mapKeys(action.payload, "_id") };
    case REMOVE_EMAILER:
      return omit(state, action.payload);
    case LOG_OUT:
      return {};
    // case REMOVE_DASHBOARD:
    //   return omit(state, action.payload);
    default:
      return state;
  }
};

export default emailerReducer;
