import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMenuData } from "src/actions/menu";
import AuthLayout from "src/layouts/AuthLayout";
import AdminLayout from "src/layouts/AdminLayout";
import UserLayout from "src/layouts/UserLayout";
import { getUser } from "src/actions/auth";

const Layout = ({ user, getUser, ...props }) => {
  const [routeLayout, setRouteLayout] = useState(<AuthLayout {...props}>{props.children}</AuthLayout>);

  const routeTypes = {
    auth: { routes: ["/forgot-password", "/verify-token", "/reset-password", "/login", "/sso-logout"], layout: <AuthLayout {...props}>{props.children}</AuthLayout> },
    error: { routes: ["/404", "/500"], layout: <>{props.children}</> },
  };

  const isAuthRouteLayout = (url) => {
    if ("/" === url) {
      setRouteLayout(routeTypes.auth.layout);
      return;
    }

    for (let type in routeTypes) {
      if (routeTypes[type]) {
        let foundRoute = routeTypes[type].routes.filter((route) => url?.includes(route));
        if (foundRoute.length > 0) {
          setRouteLayout(routeTypes[type].layout);
          return;
        }
      }
    }
  };

  const userTypeRouteLayout = async (url) => {
    if (user?.type === "user") {
      setRouteLayout(<UserLayout {...props}>{props.children}</UserLayout>);
    } else {
      setRouteLayout(<AdminLayout {...props}>{props.children}</AdminLayout>);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("userId") || !localStorage.getItem("access_token")) {
      isAuthRouteLayout(window.location.pathname);
    } else {
      userTypeRouteLayout(window.location.pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, user]);

  return <>{routeLayout}</>;
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getMenuData, getUser })(Layout);
