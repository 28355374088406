import FormContainer from "src/components/Shared/FormContainer";
import Input from "src/components/Shared/Forms/Input";
import ProfileImage from "./ProfileImage";

const EditUserForm = ({ isAdmin, setIsAdmin, name, password, confirm_password, email, username, image, notifications, groups, user = {}, setUser = () => {} }) => {
  return (
    <FormContainer>
      <div className="flex flex-wrap w-full gap-y-4">
        {/* Photo */}
        <div className="w-full px-2">
          <ProfileImage user={user} image={image} setImage={(value) => setUser(value, "image")} />
        </div>

        {/* Name */}
        <div className="sm:w-1/2 w-full px-2">
          <Input name="full-name" label="Full name" type="text" value={name} onChange={(e) => setUser(e.target.value, "name")} />
        </div>
        {(!localStorage.getItem("previewUserId") || user?.type !== "user") &&
          <>
            {/* Email */}
            <div className="sm:w-1/2 w-full px-2">
              <Input name="email" label="Email" value={email} onChange={(e) => setUser(e.target.value, "email")} />
            </div>

            <div className="w-full">
              <div className="flex w-full flex-wrap gap-y-4">
                {/* Password */}
                <div className="sm:w-1/2 w-full px-2">
                  <Input autoComplete="new-password" type="password" name="password" label="Password" value={password} onChange={(e) => setUser(e.target.value, "password")} />
                </div>
                {/* Confirm Password */}
                <div className="sm:w-1/2 w-full px-2">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="confirm-password"
                    label="Confirm password"
                    value={confirm_password}
                    onChange={(e) => setUser(e.target.value, "confirm_password")}
                  />
                </div>
              </div>
            </div>

            <div className="sm:w-1/2 w-full px-2">
              {/* Admin status */}
              <div className="relative flex flex-col pt-3.5">
                <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">Assign admin</div>
                <div className="pt-1 flex md:pt-0 items-center ">
                  <input
                    checked={isAdmin}
                    id="type"
                    aria-describedby="notifications-description"
                    name="type"
                    type="checkbox"
                    className="h-4 w-4 text-highlightColor border-gray-300 rounded"
                    onChange={() => setIsAdmin(!isAdmin)}
                  />
                  <p className="ml-3 text-sm text-gray-500">You are assigning admin status by selecting this box.</p>
                </div>
              </div>
            </div>
            <div className="sm:w-1/2 w-full px-2">
              {/* Notification */}
              <div className="relative flex flex-col pt-3.5">
                <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">Receive notifications</div>
                <div className="pt-1 md:pt-0 items-center flex">
                  <input
                    checked={notifications === true}
                    onChange={(e) => setUser(e.target.checked, "notifications")}
                    aria-describedby="notifications-description"
                    name="notifications"
                    type="checkbox"
                    className="h-4 w-4 text-highlightColor border-gray-300 rounded"
                  />
                  <p className="ml-3 text-sm text-gray-500">Get notified when company updates occur.</p>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </FormContainer>
  );
};

export default EditUserForm;
