import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { ADD_ACTIVITY_DATA, GET_ACTIVITY_DATA, DELETE_ACTIVITY_DATA } from "./type";

export const getActivity =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/list-activity`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_ACTIVITY_DATA, payload: data.data });
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  export const getActivityCount =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/count-activity`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.count;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getActivityExport =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/list-activity`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const addActivity = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/add-activity`, body);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: ADD_ACTIVITY_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteActivityData = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/remove-activity`, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: DELETE_ACTIVITY_DATA, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
