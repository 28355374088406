import { motion, useIsPresent } from "framer-motion";
import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import QuickMenu from "src/components/Shared/QuickMenu";
import { getUser } from "src/actions/auth";

import { classNames } from "src/helpers/classNames";
import UserProfile from "./UserProfile";

const UserEntry = ({ user, setSelected = () => {}, clearUserDetails, manageUserStatus, getDashboardData, setDeleteId, userAuth, site, type, getUser, ...props }) => {
  const navigate = useNavigate();

  const formatMemberDate = (date) => {
    if (!date) return "";
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  const insertTypes = {
    SSO_LOGIN: "SSO",
    MANUAL_INPUT: "Manually",
    BULK_UPLOAD: "Bulk",
  };

  const setPreviewUserId = async (id) => {
    localStorage.setItem("previewUserId", id);
    const ac = new AbortController();
    await getUser(ac.signal);
    navigate("/");
  };

  return (
    <motion.tr
      key={user?.email}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
    >
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="hidden h-10 w-10 flex-shrink-0 overflow-hidden rounded-full sm:block">{<UserProfile user={user || {}} />}</div>
          <div className="sm:ml-4">
            <div className="flex items-center gap-x-2 font-medium text-gray-900">
              {user?.name}
              {user?.default_user && <span className="rounded-full bg-gray-100 px-2 py-[1px] text-gray-400">Super Admin</span>}
            </div>

            <div className="text-gray-500">
              {insertTypes[user.creation_type]} created {formatMemberDate(user.created_at)}
            </div>
          </div>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell">
        <div className="text-gray-900">{user?.email}</div>
        <div className="text-gray-500">{user?.username}</div>
      </td>
      <td className="hidden max-h-[73.5px] px-3 py-4 text-sm text-gray-500 lg:flex">
        {(user?.permissions && user.permissions.length > 0) || (user?.group_permissions && user.group_permissions > 0) ? (
          <p
            onClick={() => {
              setSelected({ user, site });
            }}
            className="m-0 flex cursor-pointer items-center rounded-md bg-gray-50 px-[7px] py-[3px] hover:underline"
          >
            {user.permissions.length + (user?.group_permissions && user.group_permissions > 0 ? user.group_permissions : 0)} permission
            {user.permissions.length + (user?.group_permissions && user.group_permissions > 0 ? user.group_permissions : 0) > 1 ? "s" : ""}
          </p>
        ) : (
          <p className="m-0 flex cursor-default items-center rounded-md bg-gray-50 px-[7px] py-[3px]">None</p>
        )}
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
        <button onClick={() => manageUserStatus(user)} className={classNames("flex w-[52px] justify-center rounded-full px-2 text-xs font-semibold leading-5", user.active_status ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
          {user.active_status ? "Active" : "Inactive"}
        </button>
      </td>
      <td className="relative flex justify-end whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-6">
        <QuickMenu
          items={[
            {
              name: "Edit permissions",
              hideWhen: site.api_state === "DOMO_DATASET",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=dashboards` : `/update-user/${user._id}?tab=dashboards`);
              },
            },
            {
              name: "User details",
              hideWhen: site.api_state === "DOMO_DATASET",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=details` : `/update-user/${user._id}?tab=details`);
              },
            },
            {
              name: "View",
              hideWhen: site.api_state !== "DOMO_DATASET",
              onClick: () => {
                clearUserDetails(null);
                navigate(type === "admin" ? `/update-admin/${user._id}?tab=details` : `/update-user/${user._id}?tab=details`);
              },
            },
            {
              name: "Impersonate",
              hideWhen: type === "admin",
              onClick: () => {
                setPreviewUserId(user._id);
              },
            },
            {
              name: "Delete",
              hideWhen: user.default_user || site.api_state === "DOMO_DATASET",
              onClick: () => {
                setDeleteId(user._id);
              },
              color: "text-red-500",
            },
          ]}
        />
      </td>
    </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus, getUser })(UserEntry);
