import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSSODataCount } from "src/actions/sso";
import Button from "src/components/Shared/Button";
import SelectMenu from "src/components/Shared/SelectMenu";
import Search from "src/components/Users/Search";

const CardSSOForm = ({ sso, getSSODataCount, perPage, offset, groups, ...props }) => {
  const [status, setStatus] = useState("");
  const [keyword, setKeyword] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredRole = async () => {
      props.setLoaded(false);

      try {
        const ssoCount = await getSSODataCount(
          {
            default: status,
            keyword,
            limit: perPage,
            offset: offset * perPage,
          },
          ac.signal
        );

        props.setCount(ssoCount);

        const availablePages = Math.ceil(ssoCount / perPage);
        props.setPages(availablePages);
        props.setLoaded(true);
      } catch (error) {
        props.setLoaded(true);
        console.dir(error.message);
      }
    };

    loadFilteredRole();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, sso.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredRole = async () => {
      props.setLoaded(false);

      try {
        const ssoCount = await getSSODataCount(
          {
            default: status,
            keyword,
            limit: perPage,
            offset: 0,
          },
          ac.signal
        );

        props.setCount(ssoCount);

        const availablePages = Math.ceil(ssoCount / perPage);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);
      } catch (error) {
        props.setLoaded(true);
        console.dir(error.message);
      }
    };

    loadFilteredRole();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, status]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex w-full justify-between">
        <div className="flex w-4/5 gap-x-2">
          <Search keyword={keyword} setKeyword={setKeyword} />
          <div className="hidden gap-x-1 md:flex">
            <SelectMenu
              options={[
                { key: "All Status", value: "" },
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              setOption={(option) => {
                if (option.value) {
                  setStatus(option.value === "true");
                } else {
                  setStatus(option.value);
                }
              }}
            />
          </div>
        </div>
        <div className="relative flex w-[140px] items-center justify-end gap-x-1">
          <Button styleType="secondary" onClick={() => navigate("/sso/add")}>
            Add SSO
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getSSODataCount })(CardSSOForm);
