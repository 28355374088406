import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Input";
import PageHeader from "src/components/Shared/PageHeader";
import FormContainer from "src/components/Shared/FormContainer";
import { changePassword } from "src/actions/user";
import Button from "src/components/Shared/Button";

const ChangePassword = ({ changePassword, user, site }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const changeAdminPassword = async () => {
    try {
      const message = await changePassword({
        id: user?._id,
        password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      toast.success(message);
      if(localStorage.getItem("previewUserId")){
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('')
      } else {
        return navigate("/dashboards");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <PageHeader>Change Password</PageHeader>
      <FormContainer>
        <div className="flex w-full flex-col gap-y-4 px-2">
          <h2 className="font-semibold">Confirm Current</h2>
          <div className="sm:w-1/2">
            <Input name="current-password" title="Current Password" label="Enter Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
          </div>
          <h2 className="mt-4 font-semibold">New Password</h2>
          <div className="w-full sm:w-1/2">
            <Input name="new-password" title="New Password" label="Enter New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </div>
          <div className="w-full sm:w-1/2">
            <Input name="confirm-password" title="Confirm Password" label="Enter Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          </div>
        </div>
      </FormContainer>
      <div className="mt-6 flex w-full items-center justify-end space-x-4">
        {/* <button type="button" className="btn-action-primary" onClick={changeAdminPassword}>Update Password</button> */}
        <Button onClick={changeAdminPassword}>Update</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { changePassword })(ChangePassword);
