import { useEffect } from "react";
import { connect } from "react-redux";
import SelectMenu from "src/components/Shared/SelectMenu";
import { getGroupData } from "src/actions/user";
const GroupSelect = ({ groups, group, setGroup, getGroupData, className = "" }) => {
  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await getGroupData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, []);
  return (
    <>
      <SelectMenu
        className={className}
        options={[
          { key: "All Groups", value: "" },
          ...(groups.length
            ? groups.map((group, index) => {
                return {
                  key: group.name,
                  value: group._id,
                };
              })
            : []),
        ]}
        setOption={(option) => setGroup(option.value)}
      ></SelectMenu>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { getGroupData })(GroupSelect);
