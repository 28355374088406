import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDatasetAccess, pushDataPotal } from "src/actions/site";
import { domoUsersToPortalUsersAll, getGroupData, getUsers, getUsersList } from "src/actions/user";
import SelectMenu from "src/components/Shared/SelectMenu";

import { CloudArrowUpIcon, CloudArrowDownIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Button";
import GroupSelect from "./GroupSelect";
import Search from "./Search";
import { classNames } from "src/helpers/classNames";
import PreloaderIcon from "../PreloaderIcon";

const CardUserForm = ({ users, creds, getUsers, getUsersList, type, perPage, status, setStatus, group, setGroup, keyword, setKeyword, offset, groups, ...props }) => {
  const [isLoad, setLoad] = useState(false);
  const [isLoadRefreshPermissions, setIsLoadRefreshPermissions] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers(
          {
            active_status: status,
            group: group,
            keyword,
            type,
            limit: perPage,
            offset: offset * perPage,
          },
          ac.signal
        );

        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / perPage);
        props.setPages(availablePages);
        props.setLoaded(true);
      } catch (error) {
        props.setLoaded(true);
        console.dir(error.message);
      }
    };

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, users.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers(
          {
            active_status: status,
            group: group,
            keyword,
            type,
            limit: perPage,
            offset: 0,
          },
          ac.signal
        );
        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / perPage);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);
      } catch (error) {
        props.setLoaded(true);
        console.dir(error.message);
      }
    };

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, status, group]);

  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await getGroupData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertDomoUsersToPortalUsers = async (e) => {
    e.preventDefault();
    setIsLoadRefreshPermissions(true);

    // if (type === "PORTAL_CREATED") {
    //   setIsLoadRefreshPermissions(false);
    //   return;
    // }

    props.setLoaded(false);

    try {
      await props.domoUsersToPortalUsersAll();
      const userCount = await getUsers({
        active_status: null,
        group: null,
        keyword: "",
        type: "",
        limit: 10,
        offset: 0,
      });

      props.setCount(userCount);
      props.setOffset(0);
      props.setLoaded(true);
      setKeyword("");
      setStatus("");

      const availablePages = Math.ceil(userCount / 10);
      props.setPages(availablePages);
      setIsLoadRefreshPermissions(false);
    } catch (error) {
      props.setLoaded(true);
      setIsLoadRefreshPermissions(false);
      console.dir(error.message);
    }
  };
  const datasetId = props.site.portal_dataset_id;

  const [dataUrl] = useState(`https://api.domo.com/v1/json/${datasetId}/data`);
  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");

  const loadAccessToken = async () => {
    const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
    const data = await getDatasetAccess(
      null,
      null,
      {
        Authorization: `Basic ${base64Cred}`,
      },
      authUrl
    );

    return data.access_token;
  };

  const domoUpdate = async () => {
    try {
      setLoad(true);
      const userList = await getUsersList({
        active_status: status,
        keyword,
        type,
        limit: perPage,
        offset: offset * perPage,
      });
      const accessToken = await loadAccessToken();
      let ListUser = [];
      userList.forEach((item) => {
        item.permissions.forEach((permission) => {
          // ListUser.push(permission);
          ListUser.push({
            Name: item.name,
            Email: item.email,
            Notifications: "No",
            "Dashboard Name": permission.dashboard_name,
            Operator: permission.operator_name ? permission.operator_name : "",
            "Column Name": permission.column_name,
            "Column Value": permission.column_value,
          });
        });
      });

      const pushPortal = await pushDataPotal(
        ListUser,
        null,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        dataUrl
      );
      if (!pushPortal) {
        setLoad(false);
        toast.error("Something went wrong");
      } else {
        setLoad(false);
        toast.success("Successfully updated data in domo");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex w-full justify-between">
        <div className="flex w-4/5 gap-x-2">
          <Search keyword={keyword} setKeyword={setKeyword} />
          <div className="hidden gap-x-1 md:flex">
            <SelectMenu
              options={[
                { key: "All Status", value: "" },
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              setOption={(option) => {
                if (option.value) {
                  setStatus(option.value === "true");
                } else {
                  setStatus(option.value);
                }
              }}
            />

            <GroupSelect group={group} setGroup={setGroup} />
          </div>
        </div>
        <div className="relative flex w-[140px] items-center justify-end gap-x-1">
          <div className={classNames("absolute -top-14 right-0 hidden justify-end md:flex", props.site.api_state !== "DOMO_DATASET" ? "w-[160px]" : "w-[360px]")}>
            {props.site.api_state !== "DOMO_DATASET" ? (
              <Button styleType="gray" onClick={() => navigate("/bulk-user-upload")}>
                <CloudArrowUpIcon className="h-4" />
                Upload {type === "admin" ? "Admins" : "Users"}
              </Button>
            ) : type === "PORTAL_CREATED" ? (
              <Button styleType="gray" onClick={() => navigate("/bulk-user-upload")}>
                <CloudArrowUpIcon className="h-4" />
                Upload {type === "admin" ? "Admins" : "Users"} To Domo
              </Button>
            ) : (
              <div className="flex w-full justify-end gap-x-3">
                {/* <Button styleType="gray" onClick={convertDomoUsersToPortalUsers}>
                  {isLoadRefreshPermissions ? <PreloaderIcon height="h-4" /> : <CloudArrowDownIcon className="h-4" />}
                  Refresh Permissions
                </Button>
                {props.site.portal_dataset_id && (
                  <Button styleType="gray" onClick={domoUpdate}>
                    {isLoad ? <PreloaderIcon height="h-4" /> : <CloudArrowUpIcon className="h-4" />}
                    Update Domo
                  </Button>
                )} */}
              </div>
            )}
          </div>
          {props.site.api_state !== "DOMO_DATASET" && (
            <Button styleType="secondary" onClick={() => navigate(type === "admin" ? `/manage-admin${type === "admin" ? "?isAdmin=true" : ""}` : `/manage-user${type === "admin" ? "?isAdmin=true" : ""}`)}>
              Add {type === "admin" ? "Admin" : "User"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    creds: state.creds,
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { getUsers, getUsersList, domoUsersToPortalUsersAll, getGroupData })(CardUserForm);
