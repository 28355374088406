import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableBodyLoader from "src/components/Shared/Table/TableBodyLoader";

const SSOList = ({ ...props }) => {
  return (
    <Table>
      <TRHeader>
        <th scope="col" className="bg-gray-50 hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 leading-none first:rounded-tl-lg">
          Identity Provider
        </th>
        <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
          IDP Entity ID OR Issuer
        </th>
        <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
          SAML login URL
        </th>
        <th scope="col" className="bg-gray-50 hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 leading-none">
          Status
        </th>
        <th scope="col" className="bg-gray-50 relative py-3.5 pl-3 pr-3 leading-none last:rounded-tr-lg">
          <span className="sr-only">Edit</span>
        </th>
      </TRHeader>
      <TableBody>
        <TableBodyLoader props={props} colSpan="5">
          {props.children}
        </TableBodyLoader>
      </TableBody>
    </Table>
  );
};

export default SSOList;
