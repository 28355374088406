import { Menu } from "@headlessui/react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "src/actions/auth";
import { classNames } from "src/helpers/classNames";

function AdminNavDesktop({ userNavigation, logout }) {
  return (
    <>
      {userNavigation.map((item, site, updateNavigation) => (
        <Menu.Item key={item.name}>
          {({ active }) => (
            <Fragment key={item.name}>
              {item.name !== "Sign out" ? (
                <div onClick={(navItem) => updateNavigation(navItem.name)}>
                  <Link to={item.path} className={classNames(active ? "bg-color-06 bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                    {item.name}
                  </Link>
                </div>
              ) : (
                <button
                  onClick={(e) => {
                    logout(site)
                    e.preventDefault()
                  }}
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor"
                >
                  {item.name}
                </button>
              )}
            </Fragment>
          )}
        </Menu.Item>
      ))}
    </>
  )
}

export default connect(null, { logout })(AdminNavDesktop)
