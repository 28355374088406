import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TableBodyLoader from "src/components/Shared/Table/TableBodyLoader";
import TRHeader from "src/components/Shared/Table/TRHeader";

const UserList = ({ ...props }) => {
  return (
    <Table>
      <TRHeader>
        <th scope="col" className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Name
        </th>
        <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Email
        </th>
        <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Permissions
        </th>
        <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          Status
        </th>
        <th scope="col" className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Edit</span>
        </th>
      </TRHeader>
      <TableBody>
        <TableBodyLoader props={props} colSpan="5">
          {props.children}
        </TableBodyLoader>
      </TableBody>
    </Table>
  );
};

export default UserList;
