import { LockOpenIcon, PaintBrushIcon, KeyIcon } from "@heroicons/react/24/solid";
import PageHeader from "src/components/Shared/PageHeader";
import { useState } from "react";
import { classNames } from "src/helpers/classNames"; 
import SiteCredentials from "./SiteCredentials";
import SiteTheme from "./SiteTheme";
import SiteCred from "./SiteCred";

const tabs = [
  { name: "Site Credentials", icon: LockOpenIcon },
  { name: "Themes & Styles", icon: PaintBrushIcon },
  { name: "Dashboard Credentials", icon: KeyIcon },
];

const SiteSection = () => {
  const [selectedTab, setSelectedTab] = useState("Site Credentials");
  return (
    <>
      <PageHeader>Site Settings</PageHeader>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => setSelectedTab(tab.name)}
              className={classNames(
                tab.name === selectedTab ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-400",
                "group inline-flex items-center pb-3 px-1 border-b-2 font-medium text-sm cursor-pointer"
              )}
              aria-current={tab.name === selectedTab ? "page" : undefined}
            >
              <tab.icon className={classNames(tab.name === selectedTab ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "-ml-0.5 mr-2 h-5 w-5")} aria-hidden="true" />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>

      <div className="mt-4">
        {tabs.find((tab) => tab.name === selectedTab).name === "Site Credentials" ? (
          <SiteCredentials />
        ) : tabs.find((tab) => tab.name === selectedTab).name === "Dashboard Credentials" ? (
          <SiteCred />
        ) : (
          <SiteTheme />
        )}
      </div>
    </>
  );
};

export default SiteSection;
