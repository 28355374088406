import { ArrowDownTrayIcon, CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { addBulkUser } from "src/actions/user";
import Button from "src/components/Shared/Button";
import PageHeader from "src/components/Shared/PageHeader";
import { convertToBase64 } from "src/helpers/image";
import { filePath } from "src/config/host";

const BulkUserAdd = ({ addBulkUser }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.files[0]?.name) {
      setFileName(e.target.files[0]?.name);
      convertToBase64(e.target.files[0], setFile);
    }
  };

  const onSubmit = async () => {
    try {
      const message = await addBulkUser({ file });
      toast.success(message);

      return navigate("/users");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="h-full w-full flex items-center justify-between mb-4">
        <PageHeader>Add Bulk User</PageHeader>
      </div>

      <div className="relative bg-white shadow-sm p-6 rounded-md flex flex-wrap justify-between w-full sm:pr-14">
        <div className="flex flex-wrap justify-between items-end w-full gap-y-4">
          {/* Name */}
          <div className="sm:w-1/2 w-full px-2">
            <div className="grid space-y-2">
              <div className="flex text-sm font-medium">
                Select CSV File
                <span className="text-gray-400 pl-3">*Case sensitive</span>
              </div>
              <div className="flex rounded-md shadow-sm w-full">
                <div className="w-[140px] flex items-center px-3 rounded-l-md border border-gray-300 border-r-0 bg-gray-50 text-gray-500 relative overflow-hidden">
                  <CloudArrowUpIcon className="w-5 h-5" />
                  <span className="flex text-sm text-gray-600 mx-auto font-medium">Choose File</span>
                  <input type="file" className="absolute opacity-0 w-96 top-0 right-0 h-full cursor-pointer z-10" onChange={handleFileChange} accept=".csv" />
                </div>
                <input type="text" value={fileName} readOnly className="flex-1 min-w-0 block w-full px-3 py-1 h-10 rounded-r-md border-gray-300 focus:ring-0 focus:border-highlightColor" />
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end px-2">
            <a href={filePath + "/upload-users.csv"} className="font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto">
              <div className="flex items-center justif-center gap-x-2 text-sm">
                <ArrowDownTrayIcon className="ml-2 w-5 h-5" /> CSV Format
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-3 w-full flex justify-end space-x-3">
        <Button onClick={onSubmit}>Upload</Button>
      </div>
    </>
  );
};

export default connect(null, { addBulkUser })(BulkUserAdd);
