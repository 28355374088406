import { useParams } from "react-router-dom";
import ResetPasswordContent from "src/components/Auth/ResetPasswordContent";

const ResetPassword = ({ site }) => {
  const params = useParams();

  return <ResetPasswordContent token={params.token || null} />;
};

export default ResetPassword;
