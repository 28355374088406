import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { addUser, getGroupData } from "src/actions/user";
import AddPermissionForm from "src/components/Permissions/AddPermissionForm";
import Button from "src/components/Shared/Button";
import FormContainer from "src/components/Shared/FormContainer";
import PageHeader from "src/components/Shared/PageHeader";
import AddUserForm from "src/components/Users/Add/AddUserForm";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";

const ManageUserSection = ({ dashboards, operators, groups, addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [permissionFormCount, setPermissionFormCount] = useState([]);
  const [userState, setUserState] = useState({});
  const [permissionState, setPermissionState] = useState({});
  const [isRefresh] = useState(false);

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const isAdmin = queryParams.get("isAdmin");

  // Load dashboards
  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => {
      try {
        await props.getDashboardData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        await props.getOperatorData({}, ac.signal);
      } catch (error) {
        // console.dir(error.message);
      }
    };

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load groups
  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await props.getGroupData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const onSubmit = async () => {
    try {
      let userData = [];

      Object.values(userState).forEach((user) => {
        userData.push({ ...user, type: isAdmin ? "admin" : "user" });
      });

      let permission = [];
      let orderValue = 1;
      Object.values(permissionState).map((pe) => {
        pe.filters.map((flt) => {
          permission.push({
            dashboard_id: pe.dashboard_id,
            operator_id: flt.operator_id,
            column_name: flt.column_name,
            column_value: flt.column_value,
            ordering: orderValue,
          });
          orderValue++;
          return true;
        });
        return true;
      });

      await addUser({
        users: userData,
        permissions: permission,
      });

      if (isAdmin) {
        return navigate("/admins");
      } else {
        return navigate("/users");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(Object.values(permissionState), result.source.index, result.destination.index);
    setPermissionState({ ...items });
  };

  return (
    <>
      <PageHeader>{isAdmin ? "Admins" : "Users"}</PageHeader>

      <div className="my-3 w-full flex items-center justify-between">
        <h3 className="pl-2 text-xl font-semibold text-gray-500">Create {isAdmin ? "admins" : "users"}</h3>
      </div>
      {formCount.map((count, i) => {
        return <AddUserForm key={i} isAdmin={isAdmin} index={count} formCount={formCount} setFormCount={setFormCount} state={userState} setState={setUserState} groups={groups} />;
      })}

      {/* <div className="mt-10">
        <div className="my-3 w-full flex items-center justify-between">
          <h3 className="pl-2 text-xl font-semibold text-gray-500 pb-1">Assign Groups</h3>
        </div>
        <FormContainer>
          <div className="w-full grid gap-y-2"></div>
        </FormContainer>
      </div> */}
      <div className="mt-10">
        <div className="my-3 w-full flex items-center justify-between">
          <h3 className="pl-2 text-xl font-semibold text-gray-500 pb-1">Assign permissions</h3>
          <div className="flex justify-end gap-x-3">
            <Button styleType="gray" onClick={addNewPermission}>
              <PlusIcon className="h-4 w-4" />
              Add permission
            </Button>
          </div>
        </div>
        <FormContainer>
          <div className="w-full grid gap-y-2">
            {permissionFormCount.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {permissionFormCount.map((count, i) => {
                        return (
                          <Draggable key={i} draggableId={`${i}`} index={i}>
                            {(provided, snapshot) => (
                              <AddPermissionForm
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                key={i}
                                index={count}
                                dashboards={dashboards}
                                operators={operators?.map((operator) => {
                                  return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                                })}
                                formCount={permissionFormCount}
                                setFormCount={setPermissionFormCount}
                                state={permissionState}
                                setState={setPermissionState}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <span className="text-gray-400">No permissions</span>
            )}
          </div>
        </FormContainer>
      </div>
      <div className="w-full flex justify-end border-t-[1px] border-t-gray-200 mt-5 pt-3 gap-x-3">
        <Button styleType="gray" type="button" onClick={() => {}}>
          Clear all
        </Button>
        <Button type="button" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: state.dashboards,
    operators: Object.values(state.operators),
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getDashboardData, getOperatorData, getGroupData, addUser })(ManageUserSection);
