import { useEffect, useState } from "react";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import { AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getCredData,
  getDashboardData,
  manageDashboardData,
  removeDashboard,
  setDashboardData,
} from "src/actions/dashboard";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Button";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import PageHeader from "src/components/Shared/PageHeader";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { validate as uuidValidate } from "uuid";
import Input from "../Shared/Forms/Input";
import SelectMenu from "../Shared/SelectMenu";
import TextArea from "../Shared/Forms/TextArea";
import DashboardEntry from "./DashboardEntry";
import DashboardTable from "./DashboardTable";

const DashboardSection = ({
  dashboards,
  user,
  getDashboardData,
  manageDashboardData,
  removeDashboard,
  setDashboardData,
  orderingDashboardData,
  site,
  getCredData,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [startIndex, setStartIndex] = useState(-1);

  const [newDashboard, setNewDashboard] = useState({
    _id: "",
    name: "",
    dashboard_type: "embed",
    embed_type: "page",
    credential_id: null,
    embed_id: "",
    custom_html: "",
  });

  const [dashboardCredentials, setDashboardCredentials] = useState([]);
  const getDashboardCredentials = async () => {
    const ac = new AbortController();

    try {
      const res = await fetchData("POST", `${apiUrl}/dashboard/credential/get`, {}, ac.signal);
      const data = await res.json();

      if (data.status === 200) {
        let creds = data.data.map((cred) => {
          return { value: cred._id, key: cred.name };
        });
        setDashboardCredentials(creds);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    getDashboardCredentials();
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadSiteCred = async () => {
      try {
        await getCredData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    const loadDashboardData = async () => {
      try {
        await getDashboardData({}, ac.signal);
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
        console.dir(error.message);
      }
    };

    loadSiteCred();
    loadDashboardData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewDashboard = async () => {
    try {
      let dashboard = {
        credential_id: newDashboard.credential_id,
        dashboard_type: newDashboard.dashboard_type,
        custom_html: newDashboard.custom_html,
        created_at: "",
        created_by: user._id,
        deleted_at: null,
        deleted_by: null,
        embed_id: newDashboard.embed_id,
        embed_type: newDashboard.embed_type,
        name: newDashboard.name,
        updated_at: "",
        updated_by: user._id,
        _id: newDashboard._id || null,
      };

      const { message } = await manageDashboardData({
        dashboards: [dashboard],
      });

      setCreateModal(false);
      setAdvancedFeatures(false);
      toast.success(message);
      setNewDashboard({
        _id: "",
        name: "",
        dashboard_type: "embed",
        embed_type: "page",
        credential_id: null,
        embed_id: "",
        custom_html: "",
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteDashboard = async (id) => {
    const ac = new AbortController();
    if (id && !uuidValidate(id)) {
      try {
        await removeDashboard(id);
        await getDashboardData({}, ac.signal);
      } catch (error) {
        console.dir(error);
      }
    }
  };

  const editDashboard = async (dashboard) => {
    setNewDashboard(dashboard);
    setStartIndex(dashboardCredentials.findIndex((it) => it.value === dashboard.credential_id));
    setCreateModal(true);
  };

  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list)
  //   const [removed] = result.splice(startIndex, 1)
  //   result.splice(endIndex, 0, removed)

  //   return result
  // }

  // const onDragEnd = async (result) => {
  //   if (!result.destination) {
  //     return
  //   }

  //   const items = reorder(dashboards, result.source.index, result.destination.index)

  //   setDashboardData(items)
  //   try {
  //     await orderingDashboardData({ dashboards: items })
  //   } catch (error) {
  //     console.dir(error)
  //   }
  // }
  return (
    <>
      <PageHeader>Pages</PageHeader>
      {site.api_state !== "DOMO_DATASET" && (
        <div className="flex h-full w-full items-center justify-end">
          <Button
            styleType="secondary"
            onClick={() => setCreateModal(true)}>
            Add Dashboard
          </Button>
        </div>
      )}
      <ModalDefault
        isOpen={createModal}
        closeModal={() => setCreateModal(false)}
        onSubmit={createNewDashboard}
        submitText="Submit"
        cancelText="Cancel"
        title="Create a Dashboard"
        maxWidth="w-[500px]">
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-6 grid gap-y-3">
            <div className="my-5 grid space-y-2">
              <Input
                type="text"
                name="dashboard-name"
                label="Name"
                value={newDashboard.name}
                onChange={(e) => setNewDashboard({ ...newDashboard, name: e.target.value })}
              />
            </div>
            <div className="relative">
              <button
                onClick={() => {
                  newDashboard.dashboard_type === "custom"
                    ? setNewDashboard({ ...newDashboard, dashboard_type: "embed" })
                    : setNewDashboard({ ...newDashboard, dashboard_type: "custom" });
                }}
                type="button"
                className="absolute top-[1px] right-0 z-10 flex cursor-pointer items-center text-sm font-medium text-gray-400 hover:underline">
                {newDashboard.dashboard_type === "custom" ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="mr-1 h-4 w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path>
                    </svg>
                    <span>Use DOMO embed_id</span>
                  </>
                ) : (
                  <>
                    <PlusIcon className="mr-1 h-4 w-4" />
                    <span>Insert custom HTML</span>
                  </>
                )}
              </button>
              {newDashboard.dashboard_type === "custom" ? (
                <TextArea
                  label="Custom HTML"
                  value={newDashboard.custom_html}
                  onChange={(e) =>
                    setNewDashboard({ ...newDashboard, custom_html: e.target.value })
                  }
                  placeholder="<iframe> ... </iframe>"
                  textType="code"
                />
              ) : (
                <>
                  <div className="grid gap-y-3">
                    <SelectMenu
                      label="DOMO credentials"
                      startIndex={startIndex}
                      options={dashboardCredentials || [{ key: "Select credentials", value: -1 }]}
                      setOption={(option) => {
                        setNewDashboard({ ...newDashboard, credential_id: option.value });
                      }}
                    />
                    <FrontLabeledInput
                      label="Embed ID"
                      type="text"
                      value={newDashboard.embed_id}
                      onChange={(e) =>
                        setNewDashboard({ ...newDashboard, embed_id: e.target.value })
                      }
                    />
                  </div>
                  <div className="mt-5 flex justify-end">
                    <div
                      onClick={() => setAdvancedFeatures(!advancedFeatures)}
                      className="-mt-[10px] inline-flex cursor-pointer items-center gap-x-1 rounded-md py-1 px-2 text-gray-300 transition-all duration-200 sm:hover:bg-gray-50 sm:hover:text-gray-400">
                      <p>Advanced features</p>
                      <ChevronDownIcon
                        className={classNames("h-5", advancedFeatures ? "" : "-rotate-90")}
                      />
                    </div>
                  </div>
                  {/* Group image */}
                  <div
                    className={classNames(
                      "overflow-hidden transition-all duration-150",
                      advancedFeatures
                        ? "mb-4 max-h-[120px] rounded-md bg-gray-50 p-3 opacity-100"
                        : "max-h-[0px] opacity-0"
                    )}>
                    <div className="-mx-2 flex flex-wrap">
                      <div className="mb-4 flex w-full px-2">
                        <input
                          id="card-embed"
                          checked={newDashboard.embed_type === "card" ? true : false}
                          onChange={(e) => {
                            setNewDashboard({
                              ...newDashboard,
                              embed_type: e.target.checked ? "card" : "page",
                            });
                          }}
                          aria-describedby="card-embed"
                          name="card-embed"
                          type="checkbox"
                          className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                        />
                        <div className="ml-3 max-w-[400px]">
                          <label
                            htmlFor="card-embed"
                            className="text-sm text-gray-500">
                            Display dashboad as a Domo "Card"
                          </label>
                          <p className="whitespace-normal text-sm text-gray-400">
                            *Individual cards must have card embed_ids using Domo Everywhere.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        onSubmit={() => {}}
        submitText="Delete"
        cancelText="Cancel"
        title="Delete dashboard">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">
          Are you sure you want to delete this dashboard?
        </div>
      </ModalDefault>
      <AnimatePresence>
        <DashboardTable
          loaded={loaded}
          dataExists={dashboards.length > 0}>
          {dashboards.length > 0 &&
            dashboards.map((dashboard, index) => (
              <DashboardEntry
                key={dashboard._id}
                dashboard={dashboard}
                deleteDashboard={deleteDashboard}
                editDashboard={editDashboard}
              />
            ))}
        </DashboardTable>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboards: state.dashboards,
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  getDashboardData,
  manageDashboardData,
  removeDashboard,
  setDashboardData,
  getCredData,
})(DashboardSection);
