import React from "react";
import NoData from "../NoData";
import Preloader from "../Preloader";

export default function TableBodyLoader({ props, colSpan = "5" }) {
  return (
    <>
      {props.loaded && props.children}
      {props.loaded && !props.dataExists ? (
        <tr>
          <td colSpan={colSpan}>
            <NoData />
          </td>
        </tr>
      ) : !props.loaded ? (
        <tr>
          <td colSpan={colSpan}>
            <Preloader />
          </td>
        </tr>
      ) : null}
    </>
  );
}
