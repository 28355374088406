const RadioButtons = ({ heading, disabled = false, subheading, items = [] }) => {
  return (
    <div>
      <label className="text-base font-medium text-gray-900">{heading}</label>
      <p className="text-sm leading-5 text-gray-500">{subheading}</p>
      <fieldset className="mt-4">
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {items.map((item) => (
            <div key={item.id} className="w-full flex gap-x-3 items-center">
              <input
                type="radio"
                name={item.name}
                checked={item.checked}
                disabled={disabled}
                value={item.id}
                onChange={item.onChange}
                className="h-4 w-4 text-highlightColor ring-none focus:outline-none border-gray-300"
              />
              <label htmlFor={item.id} className="block mt-auto mb-auto text-sm font-medium text-gray-700">
                <div className="w-full">{item.name}</div>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioButtons;
