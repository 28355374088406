import { combineReducers } from "redux";
import authReducer from "src/reducers/authReducer";
import userReducer from "src/reducers/userReducer";
import dashboardReducer from "src/reducers/dashboardReducer";
import siteSettingsReducer from "src/reducers/siteReducer";
import operatorReducer from "src/reducers/operatorReducer";
import userDetailsReducer from "src/reducers/userDetailsReducer";
import collapseReducer from "src/reducers/collapseReducer";
import menuReducer from "src/reducers/menuReducer";
import datasetUserReducer from "src/reducers/datasetUserReducer";
import portalUserReducer from "src/reducers/portalUserReducer";
import groupReducer from "src/reducers/groupReducer";
import emailerReducer from "src/reducers/emailerReducer";
import datasetPortalUserReducer from "src/reducers/datasetPortalUserReducer";
import ssoReducer from "src/reducers/ssoReducer";
import searchReducer from "src/reducers/searchReducer";
import activityReducer from "src/reducers/activityReducer";
import siteCredReducer from "./siteCredReducer";

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  dashboards: dashboardReducer,
  site: siteSettingsReducer,
  operators: operatorReducer,
  userDetails: userDetailsReducer,
  collapse: collapseReducer,
  menus: menuReducer,
  search: searchReducer,
  sso: ssoReducer,
  activity: activityReducer,
  creds: () => {
    return {
      clientId: "ac807a50-a522-4dd4-8cac-2ff0a3544e2d",
      clientSecret: "b1ffda60aa2fb8478ae61a90380caf45cf953041e4198505e283ee4484b912e7",
      datasetId: "a0f1e7d0-ea47-4287-8fc3-d2aafd53bd24",
      portalDatasetId: "8eec335b-67f0-4098-a405-31e49bf5a0e8",
    };
  },
  datasetUsers: datasetUserReducer,
  datasetPortalUsers: datasetPortalUserReducer,
  portalUsers: portalUserReducer,
  groups: groupReducer,
  emailers: emailerReducer,
  siteCred: siteCredReducer
});
