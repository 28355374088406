import NoProfile from "src/assets/img/user-default.png";
import NoFavi from "src/assets/img/favi-default.png";
import ExampleFavi from "src/assets/img/favicon-example.png";
import NoLogo from "src/assets/img/logo-default.png";
import NoBusiness from "src/assets/img/business-default.png";

/* Digital Ocean: Example */
// export const apiPort = "";
// export const baseUrl = "https://domo-portal-admin-api-29ypc.ondigitalocean.app";

export const apiPort = ":" + 3003;

/* Production */
// export const baseUrl = `https://biportalhfcadmin.gohfc.com${apiPort}`;

/* Localhost */
export const baseUrl = `http://homefranchisev2.iosx.in${apiPort}`;

export const apiUrl = baseUrl + "/api";
export const filePath = baseUrl + "/uploads";
export const noProfile = NoProfile;
export const noFavi = NoFavi;
export const exampleFavi = ExampleFavi;
export const noLogo = NoLogo;
export const noBusiness = NoBusiness;