import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getUsersList } from "src/actions/user";
import { manageSiteSettings, manageAutoSiteSettings, manageDevToDomo } from "src/actions/site";

function DatasetModal({ isOpen = false, closeModal = () => {}, submitText, cancelText, title, children, onSubmit = () => {}, removeSubmit = false, removeClose = false, getUsersList, manageAutoSiteSettings, ...props }) {
  const [datasetInfo, setDatasetInfo] = useState({
    name: "",
    description: "",
  });
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const inputChange = (e) => {
    setDatasetInfo({
      ...datasetInfo,
      [e.target.name]: e.target.value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const createData = async () => {
    setLoader(true);
    try {
      const siteData = await manageDevToDomo(datasetInfo);
      manageAutoSiteSettings(siteData.data);

      props.setRrefresh(true);
      props.setIsOpen(!isOpen);
      setLoader(false);

      /* const accessToken = await loadAccessToken();

      const createSet = await createDataset(
        datasetInfo,
        null,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        dataUrl
      );
      const result = JSON.parse(createSet);

      if (result.status === 400) {
        toast.error(result.message);
      } else {
        const pd = result.id;
        const dataset_name = result.name;
        props.manageSiteSettings({ portal_dataset_id: pd, dataset_name: dataset_name });
        // toast.success('Successfully added dataset');
        //$("#datasetModal").modal("toggle");
        props.setRrefresh(true);

        const userList = await getUsersList({ limit: 10000, offset: 0, type: "user" });
        let ListUser = [];
        userList.forEach((item) => {
          item.permissions.forEach((permission) => {
            ListUser.push({
              Name: item?.name || "",
              Email: item?.email || "",
              Notifications: "No" || "",
              "Dashboard Name": permission?.dashboard_name || "",
              Operator: permission.operator_name ? permission.operator_name : "",
              "Column Name": permission?.column_name || "",
              "Column Value": permission?.column_value || "",
            });
          });
        });
        const pushPortal = await pushdata(ListUser, accessToken, pd);

        if (!pushPortal) {
          toast.error("Oops, something went wrong..");
        } else {
          result && result.error && toast.error(result.error_description);
          result && !result.error && toast.success("Successfully added dataset in domo");
          props.setIsOpen(!isOpen);
        }
        setLoader(false);
      } */
    } catch (error) {
      console.dir(error);
      props.setIsOpen(!isOpen);
      toast.error("Oops, something went wrong..");
      setLoader(false);
    }
    //toast.success(message);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => props.setIsOpen(!isOpen)}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full p-0">
                <div className="flex justify-between py-4 px-4">
                  <Dialog.Title as="h3" className="text-lg text-left leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => props.setIsOpen(!isOpen)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="px-4">
                  <div className="w-full mb-3">
                    <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input type="text" name="name" value={datasetInfo.name} onChange={inputChange} className="block w-full sm:text-sm border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor" />
                  </div>
                  <div className="w-full mb-3">
                    <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Description</label>
                    <textarea type="text" name="description" value={datasetInfo.description} onChange={inputChange} className="block w-full sm:text-sm border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor h-36"></textarea>
                  </div>
                </div>
                <div className="p-4 sm:flex sm:flex-row-reverse">
                  {!removeSubmit && (
                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-highlightColor text-base font-medium text-white  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" onClick={createData}>
                      {loader && (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>{" "}
                          Processing..
                        </>
                      )}

                      {!loader && submitText}
                    </button>
                  )}
                  {!removeClose && (
                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm" onClick={() => props.setIsOpen(!isOpen)}>
                      {cancelText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default connect(null, { manageSiteSettings, getUsersList, manageAutoSiteSettings })(DatasetModal);
