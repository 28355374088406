import { Listbox, Transition } from "@headlessui/react";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";

import { classNames } from "src/helpers/classNames";

export default function ButtonWithDropdown({ options, defaultText = "Select an option", disabled = false, emptyListText = "No listed items", setOption, label, startIndex = 0, buttonText = "", className = "", onSubmit = () => {} }) {
  const [selected, setSelected] = useState(0);
  const handleSelection = (e) => {
    setSelected(e);
    setOption(e);
  };

  useEffect(() => {
    if (startIndex >= 0) {
      setSelected(options[startIndex]);
    } else {
      setSelected({ key: defaultText, value: -1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);

  return (
    <div className="relative flex border border-gray-300 bg-white rounded-md text-gray-500">
      <button className="hover:bg-gray-50 h-full flex items-center justify-center px-3 rounded-tl-md rounded-bl-md border-r border-gray-300" onClick={onSubmit}>
        <div className="flex items-center justif-center gap-x-2 font-medium text-sm">
          {buttonText} <DocumentChartBarIcon className="h-5 w-5" />
        </div>
      </button>
      <Listbox value={selected} onChange={handleSelection}>
        {({ open }) => (
          <>
            {label && <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
            <div className="relative flex">
              <Listbox.Button className={classNames("bg-white hover:bg-gray-50 relative w-full pl-2.5 pr-7 py-2 text-left focus:outline-none rounded-tr-md rounded-br-md", disabled ? "bg-gray-100" : "")}>
                <span className="block truncate font-medium w-[24px] text-medium">{options.length ? (selected ? selected.key : defaultText) : emptyListText}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className={classNames("absolute top-10 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm")}>
                  {options.map((option) => (
                    <Listbox.Option key={option.key} className={({ active }) => classNames(active ? "text-highlightColor cursor-pointer" : "text-gray-900", "hover:bg-gray-50 select-none relative py-2 pl-3 pr-9")} value={option}>
                      {({ selected, active }) => (
                        <>
                          <p className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{option.key}</p>

                          {selected ? (
                            <span className={classNames(active ? "text-highlightColor" : "text-gray-900", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                              <CheckIcon className="text-green-600 h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
