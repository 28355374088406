import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getUser, logout } from "src/actions/auth";

const ProtectedRoute = ({ roles = ["admin"], user, auth, children, getUser, site, logout }) => {
  const navigate = useNavigate();

  // const [loadedRoute, setLoadedRoute] = useState(false)

  useEffect(() => {
    const ac = new AbortController();

    const loadUser = async () => {
      if (!auth.status && localStorage.getItem("access_token")) {
        try {
          await getUser(ac.signal);
        } catch (error) {
          logout();
          console.dir(error.message);
        }
      } else if (localStorage.getItem("userId") && !localStorage.getItem("access_token")) {
        logout();
      }
    };

    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status, getUser, logout, window.location.pathname]);

  useEffect(() => {
    if (roles && user) {
      if (!roles.includes(user.type)) {
        if (user.type === "admin") {
          navigate("/dashboards");
        }
        if (user.type === "user") {
          // navigate("/analytics");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, window.location.pathname]);

  useEffect(() => {
    if (site && site.api_state === "DOMO_DATASET" && window.location.pathname === "/bulk-user-upload") {
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site.api_state, window.location.pathname]);

  if (!localStorage.getItem("access_token")) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUser, logout })(ProtectedRoute);
