const Table = ({ children }) => {
  return (
    <div className="flex flex-col">
      <div className="w-full mt-3">
        <div className="shadow-md ring-1 ring-black ring-opacity-5 rounded-md md:rounded-lg">
          <table className="table-auto w-full divide-y divide-gray-300">{children}</table>
        </div>
      </div>
    </div>
  );
};

export default Table;
