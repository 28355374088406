// import { useEffect } from "react";
import UsersPageContent from "src/components/Users/UsersPageContent";
// import axios from "axios";

const Users = () => {
  // const airslateToken = "cME4oDXBFdeS8RDpdVzROrmAUstzGP2OC2ky3MAoMj633jGyZRpHicCDpHO3yFu2";
  // // "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL29hdXRoLmFpcnNsYXRlLmNvbS8iLCJhdWQiOiIwYmIxZGJjOS1hZTI5LTQxOWYtYjE1Zi00ZmRmMDJjNmY3MDUiLCJqdGkiOiI0NDgwMmZkYjU1ZTNlMjYyMDYwZjdhNDZiMDFiMzhhMDVlODUzZGJkODEzYzg1OGE2NzI0Y2JhOTk3M2ZlMGMwYjJkNjAwOThjZTE0ZjRiNSIsImlhdCI6MTY2OTk5OTkzMS4yMzY1NTQsIm5iZiI6MTY2OTk5OTkzMS4yMzY1NTUsImV4cCI6MTY3MDE3MjczMS4yMjAxMSwic3ViIjoiYmI5MmI5MzMtMDMxNC00YWZhLWJjODgtNjg4NWQ5YmU0NWRjIiwic2NvcGVzIjpbImVtYWlsIl19.gT9rskcgyNtA904QGCCCVNNSIMkeuKgCuIfFasF2FwXQqBdEz3Rp9KL0lI7mg0k-_wO52aM9nP878ZVnz95scTplNinfsOFln2LwyVzbDkG7Gy2Lss0PNpoXTPzOGHfwjuMaajTUcRk86bReGp5y0arMevYxNaxPwM9OiSX2PJZ98wFhcQs_Wm_l0lqvXx4QhWPmv5DyUSvtuBu9WptJlguJ7vjCRF13bvGMp7f0tWvLo9pgzmWEriYuvRbmVuhPHANKtAuAN6o1HQh_qAYWLqFbrejKdB-tLLIhlR-OWGsr7nhkKOmTGRPhMZ7ZKikYKt3cDiIsQGTHI407CFAhkHEJbIBECtXxI-Xtaf823L73GTT1Wh_OfS07Up21FPmNwgwMoZy6nT6Lz4d8ftkA2NFGFjog_LTL7SM3O4Kn5wXX2k6dwU-yfWASLlYSjJp4lvNWASJ5QmUnxw1HqYXwhxu2HMB6omIQSMzDHgzw5yI5Gi3GL7IEAf_kTrB7E8R0B3wez7KV0Aid3wj1LLLCQmOAPzHySOTYdrdGQ9fCm-Kv8oCWo_lzPpDAdknrL4dhRbdPSNaSFPIEDjm1YfWdujnS0Z6j7eW1YK781kb3911RC8yi-XTEMPRAjk-cWn13of1K4NmI74MnkKAl77xFJbjFaCJcj_x3M5gdQNNLIK8";
  // const orgId = "F207FB2E-5200-0000-0000D981";
  // const templateId = "56829BF0-2400-0000-0000BA29";
  // const flowId = "67F4F26C-D110-0000-0000C9C1";
  // const documentId = "E001ECEF-C920-0000-000021F6";

  // useEffect(() => {
  //   const getAirSlate = async () => {
  //     // let organizations = await axios.get("https://api.airslate.io/v1/organizations", {
  //     //   headers: {
  //     //     Authorization: `Bearer ${airslateToken}`,
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // });
  //     // let templates = await axios.get("https://api.airslate.io/v1/organizations/F207FB2E-5200-0000-0000D981/templates", {
  //     //   headers: {
  //     //     Authorization: `Bearer ${airslateToken}`,
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // });
  //     // let template = await axios.get(`https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}`, {
  //     //   headers: {
  //     //     Authorization: `Bearer ${airslateToken}`,
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // });

  //     // console.log(template);

  //     // let newFlow = await axios.post(
  //     //   `https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/flows`,
  //     //   {
  //     //     documents: [
  //     //       // {
  //     //       //   id: "28400000-0000-0000-000000F6",
  //     //       //   fields: [
  //     //       //     {
  //     //       //       name: "Choice 1",
  //     //       //       value: "options-1",
  //     //       //       options: [
  //     //       //         {
  //     //       //           label: "options 1",
  //     //       //           value: "options-1",
  //     //       //         },
  //     //       //         {
  //     //       //           label: "options 2",
  //     //       //           value: "options-2",
  //     //       //         },
  //     //       //       ],
  //     //       //     },
  //     //       //   ],
  //     //       // },
  //     //     ],
  //     //   },
  //     //   {
  //     //     headers: {
  //     //       Authorization: `Bearer ${airslateToken}`,
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }
  //     // );

  //     // console.log("newFlow", newFlow);

  //     // let document = await axios.get(`https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/documents`, {
  //     //   headers: {
  //     //     Authorization: `Bearer ${airslateToken}`,
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // });

  //     // console.log("document", document);

  //     // let flowDocs = await axios.get(`https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/flows/${flowId}`, {
  //     //   headers: {
  //     //     Authorization: `Bearer ${airslateToken}`,
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // });

  //     // console.log("flowDocs", flowDocs);

  //     const templateFormId = "5867A5CF-C920-0000-000021F6";

  //     let formfill = await axios.post(
  //       `https://integrations.airslate.com/html-form-builder/v1/form/fill`,
  //       {
  //         id: "546A8A3F-C920-0000-000021F6",
  //         isAdminUser: true,
  //         isDraft: true,
  //         json: {
  //           Amount: "900",
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${airslateToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("formfill", formfill);

  //     let formTemplates = await axios.put(
  //       `https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/forms/${templateFormId}`,
  //       {
  //         name: "Matt's form?",
  //         form_template_id: "14D91000-0000-0000-000021F6",
  //         fields: [
  //           {
  //             name: "Amount",
  //             type: "currency",
  //             value: 200,
  //           },
  //         ],
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${airslateToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("formTemplates", formTemplates);

  //     // let documentUpdate = await axios.patch(
  //     //   `https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/documents/${documentId}`,
  //     //   {
  //     //     fields: [
  //     //       {
  //     //         name: "Amount",
  //     //         value: "300",
  //     //       },
  //     //     ],
  //     //   },
  //     //   {
  //     //     headers: {
  //     //       Authorization: `Bearer ${airslateToken}`,
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }
  //     // );

  //     // console.log("documentUpdate", documentUpdate);

  //     let shareable = await axios.get(`https://api.airslate.io/v1/organizations/${orgId}/templates/${templateId}/flows/flow_id/share`, {
  //       headers: {
  //         Authorization: `Bearer ${airslateToken}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   };
  //   getAirSlate();
  // }, []);
  return <UsersPageContent type="user" />;
};

export default Users;
