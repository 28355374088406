import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { GET_USERS, GET_USER, GET_USER_DETAILS, CLEAR_USER_DETAILS, REMOVE_USER, LOGGED_IN, GET_DATASET_USERS, GET_PORTAL_USERS, DOMO_USERS_TO_PORTAL_USERS, GET_GROUP_DATA, REMOVE_GROUP, ADD_GROUP, EDIT_GROUP } from "./type";

export const addUser = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/add`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGroupData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/group/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_GROUP_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getGroupDataCount =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/group/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_GROUP_DATA, payload: data.data });
        return data.count;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const addGroup = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/group/manage`, body);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: ADD_GROUP, payload: data.data });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editGroupData = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/group/edit `, body);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: EDIT_GROUP, payload: data.data });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteGroupData = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/group/remove `, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: REMOVE_GROUP, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addBulkUser = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/bulk-user-upload`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUser = (body) => async (dispatch, getState) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/edit`, body);
    const data = await res.json();

    if (data.status === 200) {
      const state = getState();

      if (state.auth.user?._id === data.data?._id) {
        dispatch({ type: LOGGED_IN, payload: data.data });
      }

      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageUserStatus = (user) => async (dispatch, getState) => {
  try {
    const state = getState();
    const findUser = Object.values(state.users).find((userData) => userData._id === user._id);
    if (findUser && !findUser.default_user) {
      if (state.site.api_state === "DOMO_DATASET") {
        return;
      }
    }

    const res = await fetchData("POST", `${apiUrl}/user/manage-status`, { id: user._id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_USER, payload: { ...user, active_status: !user.active_status } });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUsers =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/user/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_USERS, payload: data.data });
        return data.count;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getUsersExport =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/user/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getUsersList =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/user/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_USERS, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getUserDetails =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/user/details`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_USER_DETAILS, payload: data.data });
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const clearUserDetails =
  (body = null) =>
  (dispatch) =>
    dispatch({
      type: CLEAR_USER_DETAILS,
      payload: {},
    });

export const removeUserPermission = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/permission/remove`, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: "EMPTY", payload: {} });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/change-password`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/user/remove`, { id });
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: REMOVE_USER, payload: id });
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDatasetUsers =
  (body = {}, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/dataset-user/row/list`, body, signal);
      const resData = await res.json();

      if (resData.status !== 200) {
        throw new Error(resData.message);
      } else {
        dispatch({ type: GET_DATASET_USERS, payload: resData.data });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getPortalUsers =
  (body = {}, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/portal/user/list`, body, signal);
      const resData = await res.json();

      if (resData.status !== 200) {
        throw new Error(resData.message);
      } else {
        dispatch({ type: GET_PORTAL_USERS, payload: resData.data });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const domoUsersToPortalUsers = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/dataset-user/manage`, body);
    const resData = await res.json();

    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: DOMO_USERS_TO_PORTAL_USERS, payload: resData.data });
      return resData.message;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const domoUsersToPortalUsersAll = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const res = await fetchData("GET", `${apiUrl}/dataset-user-cron/manage/${state.site.dataset_client_id}.${state.site.dataset_id}`);
    // `${apiUrl}/dataset-user-cron/manage/${state.site.dataset_client_id}.${state.site.dataset_id}.${state.site.cron_token}`
    const data = await res.json();

    if (data.status !== 200) {
      throw new Error(data.message);
    }

    return data.message;
  } catch (error) {
    throw new Error(error.message);
  }
};
