// import { CubeTransparentIcon } from "@heroicons/react/24/solid"
import { motion, useIsPresent } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Button";
import { getDashboardData, manageDashboardData } from "src/actions/dashboard";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import QuickMenu from "src/components/Shared/QuickMenu";
import { apiUrl } from "src/config/host";
import { validate as uuidValidate } from "uuid";
import Input from "../Shared/Forms/Input";
import SelectMenu from "../Shared/SelectMenu";
import TextArea from "../Shared/Forms/TextArea";

// const DashboardEntry = ({ placeholder, innerRef, draggableProps, dragHandleProps, dashboard, isDefault, updateDefaultKey, deleteDashboard, editDashboard, getDashboardData, manageDashboardData, ...props }) => {
const DashboardEntry = ({ dashboard, isDefault, updateDefaultKey, deleteDashboard, editDashboard, getDashboardData, manageDashboardData, ...props }) => {
  const [changedState, setChangedState] = useState(false);
  const [tempDashboard, setTempDashboard] = useState(dashboard);

  const [dashboardCredentials, setDashboardCredentials] = useState([]);

  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  useEffect(() => {
    setTempDashboard(dashboard);
  }, [dashboard]);

  useEffect(() => {
    const getDashboardCredentials = async () => {
      const ac = new AbortController();

      try {
        const res = await fetchData("POST", `${apiUrl}/dashboard/credential/get`, {}, ac.signal);
        const data = await res.json();

        if (data.status === 200) {
          let creds = data.data.map((cred) => {
            return { value: cred._id, key: cred.name };
          });
          setDashboardCredentials(creds);
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
      return () => ac.abort();
    };

    getDashboardCredentials();
  }, []);

  const updateDashboard = (payload, key) => {
    setTempDashboard({ ...tempDashboard, ...payload });
    setChangedState(true);
  };

  const undoStateChanges = () => {
    setTempDashboard(dashboard);
    setChangedState(false);
  };

  const onSubmit = async () => {
    try {
      let item = tempDashboard;
      if (uuidValidate(item._id)) {
        item._id = null;
      }

      const { message } = await manageDashboardData({
        dashboards: [item],
      });

      toast.success(message);
      setChangedState(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
      // ref={innerRef}
      // {...draggableProps}
      className="group relative flex w-full flex-col items-start justify-between rounded-md bg-white py-4 sm:table-row"
    >
      {/* {placeholder} */}
      <td className="relative flex w-full whitespace-nowrap bg-white px-3 pt-5 align-top text-sm text-gray-500 sm:table-cell sm:w-[50%] sm:py-4 md:min-w-[200px] lg:block lg:w-[100%] lg:min-w-[320px] lg:py-4 lg:pl-6 lg:align-top xl:min-w-[400px] xl:items-center">
        <div className="flex w-full items-center sm:block">
          {/* <p {...dragHandleProps} className="hidden sm:group-hover:flex absolute left-5 -bottom-4 p-1.5 rounded-md bg-gray-50">
            <CubeTransparentIcon className="text-gray-300 mr-2 h-5 w-5" />
            <span className="text-gray-400">Drag to reorder</span>
          </p> */}
          <div className="mb-3 w-full">
            <Input type="text" name="dashboard-name" label="Name" error={false === "Name field is required"} value={tempDashboard.name} onChange={(e) => updateDashboard({ name: e.target.value })} />
          </div>
        </div>
      </td>
      <td className="block h-full w-full bg-white px-3 text-sm text-gray-500 sm:table-cell sm:py-4 lg:py-4 lg:pr-5 lg:align-top">
        <div className="">
          <div className="items-top flex h-full flex-col justify-start gap-y-3 lg:mt-0 xl:max-w-5xl">
            {tempDashboard.dashboard_type === "custom" ? (
              <>
                <div className="h-full w-full xl:w-4/5">
                  <TextArea label="Custom HTML" textType="code" value={tempDashboard.custom_html} onChange={(e) => updateDashboard({ custom_html: e.target.value })} />
                </div>
              </>
            ) : (
              <>
                <div className="h-full w-full xl:w-4/5">
                  <SelectMenu
                    label="Domo Credential"
                    disabled={dashboardCredentials.length === 0}
                    options={dashboardCredentials || [{ key: "Select credentials", value: -1 }]}
                    startIndex={dashboardCredentials.length ? dashboardCredentials.map((object) => object.value).indexOf(dashboard.credential_id) : -1}
                    setOption={(option) => {
                      updateDashboard({ credential_id: option.value });
                    }}
                    emptyListText="No credentials have been created."
                  />
                </div>
                <div className="w-full xl:mr-3 xl:w-4/5">
                  <FrontLabeledInput label="Embed ID" type="text" value={tempDashboard.embed_id} onChange={(e) => updateDashboard({ embed_id: e.target.value })} />
                </div>
              </>
            )}
          </div>
          {props.site.api_state !== "DOMO_DATASET" && (
            <div className="mt-3 flex h-full max-h-[40px] w-full items-center justify-end gap-x-2 bg-white lg:hidden">
              <Button styleType="gray" disabled={!changedState} onClick={undoStateChanges}>
                Undo
              </Button>
              <Button styleType="secondary" disabled={!changedState} onClick={onSubmit}>
                Save changes
              </Button>
            </div>
          )}
        </div>
      </td>
      <td className="absolute top-0 right-0 block h-8 w-full whitespace-nowrap bg-transparent align-bottom text-sm text-gray-500 sm:relative sm:m-0 sm:table-cell sm:h-8 sm:w-auto sm:bg-white sm:px-3 sm:py-4">
        {props.site.api_state !== "DOMO_DATASET" && (
          <>
            <div className="absolute top-2 right-2">
              <QuickMenu
                items={[
                  { name: "Edit", onClick: () => editDashboard(dashboard), color: "text-grey-500" },
                  { name: "Delete", onClick: () => deleteDashboard(dashboard._id), color: "text-red-500" },
                ]}
              />
            </div>
            <div className="hidden w-full items-center justify-end gap-x-2 lg:flex">
              <Button styleType="gray" disabled={!changedState} onClick={undoStateChanges}>
                Undo
              </Button>
              <Button styleType="secondary" disabled={!changedState} onClick={onSubmit}>
                Save changes
              </Button>
            </div>
          </>
        )}
      </td>
    </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getDashboardData, manageDashboardData })(DashboardEntry);
