import React from "react";
import { classNames } from "src/helpers/classNames";
import PreloaderIcon from "../PreloaderIcon";

const Preloader = ({ text = "Loading...", className = "" }) => {
  return (
    <div className={classNames("flex justify-center items-center gap-x-4 w-full h-[120px]", className)}>
      <PreloaderIcon height="h-14" widht="w-14" />
      <p className="m-0 text-lg text-gray-400">{text}</p>
    </div>
  );
};

export default Preloader;
