import React from "react";

const NoData = () => {
  return (
    <div className="flex justify-center items-center gap-x-4 w-full h-[120px]">
     <p className="m-0 text-md font-medium text-gray-400">No Data Found</p>
    </div>
  );
};

export default NoData;
