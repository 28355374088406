import mapKeys from "lodash/mapKeys";
import { GET_DATASET_USERS } from "src/actions/type";

const datasetUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DATASET_USERS:
      return { ...mapKeys(action.payload, "_id") };
    default:
      return state;
  }
};

export default datasetUserReducer;
