import { classNames } from "src/helpers/classNames";

const Input = ({
  autoComplete = "off",
  label,
  name,
  disabled = false,
  value = "",
  error = false,
  type = "text",
  labelClassNames = "",
  onChange = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
}) => {
  return (
    <div className="relative">
      <label htmlFor={name} className={classNames(labelClassNames, "flex justify-between text-sm font-medium text-gray-700 mb-1", error ? "text-red-600" : "")}>
        {label} <div className="pl-2">{error ? "*Required" : ""}</div>
      </label>
      <div className="">
        <input
          disabled={disabled}
          value={value}
          type={type}
          name={name}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={classNames("block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor", error ? "border-red-300" : "", disabled ? "text-gray-400 cursor-text" : "")}
        />
      </div>
    </div>
  );
};

export default Input;
