import React, { useEffect, useRef, useState } from "react";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import SelectMenu from "src/components/Shared/SelectMenu";
function Search({
  users,
  groups,
  setGroup,
  setStateDate,
  setEndDate,
  dashboards,
  setDashboard,
  setUser,
  className = "",
  offset = 0,
  setOffset = () => {},
}) {
  const [flatdate, setDate] = useState(null);

  const fp = useRef(null);
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const enddate = new Date();
  enddate.setHours(23);
  enddate.setMinutes(59);

  // eslint-disable-next-line no-unused-vars
  const [value, onChange] = useState([date.toISOString(), enddate.toISOString()]);

  // useEffect(() => {
  //   setStateDate(value ? new Date(value[0]).toISOString() : "");
  //   setEndDate(value ? new Date(value[1]).toISOString() : "");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value]);

  useEffect(() => {
    if (flatdate?.e && flatdate?.e[0] && flatdate?.e[1]) {
      let first = flatdate?.e[0] ? new Date(flatdate?.e[0]).toISOString() : "";
      let last = flatdate?.e[1] ? new Date(flatdate?.e[1]).toISOString() : "";

      setStateDate(first);
      setEndDate(last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatdate?.e[0] && flatdate?.e[1]]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex w-full justify-between">
          <div className="flex w-4/5 gap-x-2">
            <div className="hidden gap-x-1 md:flex">
              <div className="flex w-full sm:w-[290px] xl:w-[420px]">
                <label
                  htmlFor="search"
                  className="sr-only">
                  Search
                </label>
                <div className="relative flex w-full rounded-md shadow-sm">
                  <Flatpickr
                    ref={fp}
                    className="w-full rounded-md border-gray-300 text-sm focus:border-highlightColor focus:ring-0"
                    // id="datepicker-publish-input"
                    placeholder="Select dates"
                    onChange={(e) => {
                      setOffset(0);
                      setDate({ e });
                    }}
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      mode: "range",
                      dateFormat: "d.m.y",
                    }}
                  />
                </div>
              </div>
              <SelectMenu
                className={className}
                options={[
                  { key: "All Users", value: "" },
                  ...(users.length
                    ? users.map((user, index) => {
                        return {
                          key: user.name,
                          value: user._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => {
                  setOffset(0);
                  setUser(option.value);
                }}></SelectMenu>
              {/* <SelectMenu
                className={className}
                options={[
                  { key: "All Groups", value: "" },
                  ...(groups.length
                    ? groups.map((group, index) => {
                        return {
                          key: group.name,
                          value: group._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => setGroup(option.value)}></SelectMenu> */}

              <SelectMenu
                className={className}
                options={[
                  { key: "All Pages", value: "" },
                  ...(dashboards.length
                    ? dashboards.map((dashboard, index) => {
                        return {
                          key: dashboard.name,
                          value: dashboard._id,
                        };
                      })
                    : []),
                ]}
                setOption={(option) => {
                  setOffset(0);
                  setDashboard(option.value);
                }}></SelectMenu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
