// import mapKeys from "lodash/mapKeys";
// import omit from "lodash/omit";
import {
  GET_DASHBOARD_DATA,
  LOG_OUT,
  // REMOVE_DASHBOARD
} from "src/actions/type";

const dashboardReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return action.payload;
    case LOG_OUT:
      return [];
    // case REMOVE_DASHBOARD:
    //   return omit(state, action.payload);
    default:
      return state;
  }
};

export default dashboardReducer;
